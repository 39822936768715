import { check } from "./type_util";

export class ArrayUtil
{
	static areEqual(a: unknown[], b : unknown[]): boolean
	{
		return a.length === b.length && a.every((v, i) =>  v === b[i]);
	}

	static shuffleSelf<T>(array: T[]): T[]
	{
		let i = array.length;
		while (i !== 0) {
			const k = Math.floor(Math.random() * i);
			i--;
			const t = array[i];
			array[i] = array[k];
			array[k] = t;
		}
		return array;
	}

	static getLast<T>(array: readonly T[]): T
	{
		const length = array.length;
		check(length > 0, "Array is empty!");
		return array[length - 1];
	}

	static lastOrDefault<T, V>(array: readonly T[], defaultValue: V): T | V
	{
		const length = array.length;
		return length > 0 ? array[length - 1] : defaultValue;
	}

	static getRandomItem<T>(array: readonly T[]): T
	{
		const length = array.length;
		check(length > 0, "Array is empty!");
		return array[Math.floor(Math.random() * length)];
	}

	static sum<T>(array: readonly T[], getter: (it: T) => number): number
	{
		let sum = 0;
		for (const it of array) {
			sum += getter(it);
		}
		return sum;
	}

	static min<T>(array: readonly T[], getter: (it: T) => number): number
	{
		const length = array.length;
		check(length > 0, "invalid length");
		let min = getter(array[0]);
		for (let i = 1; i < length; i++) {
			const item = getter(array[i]);
			if (item < min)
				min = item;
		}
		return min;
	}

	static max<T>(array: readonly T[], getter: (it: T) => number): number
	{
		const length = array.length;
		check(length > 0, "invalid length");
		let max = getter(array[0]);
		for (let i = 1; i < length; i++) {
			const item = getter(array[i]);
			if (item > max)
				max = item;
		}
		return max;
	}

	static removeSelf<T>(array: T[], condition: (it: T) => boolean)
	{
		for (let i = array.length - 1; i >= 0; i--) {
			if (condition(array[i]))
				array.splice(i, 1);
		}
	}

	static toMap<K, V>(array: readonly V[], key: (v: V) => K): Map<K, V>
	{
		const result = new Map<K, V>();
		for (const it of array) {
			result.set(key(it), it);
		}
		return result;
	}
}

