import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { SideBarContext } from "./App";
import { app } from "./AppContext";
import { ContentModifier } from "./ContentModifier";
import { useOnMount } from "./hooks/UseOnMount";

function SideBarBtn(p: { togglerSideBar: () => void, sideBar: boolean }) {
    return (
        <button
            className={p.sideBar ? "side-bar cross" : "side-bar"}
            onClick={p.togglerSideBar}>
            <div className="menu-btn"/>
            <div className="menu-btn"/>
            <div className="menu-btn"/>
        </button>
    );
}

export function ContentPage(p: { data: string, modifier: ContentModifier }) {

    const [key, setKey] = useState(1);

    useOnMount(() => {
        app.onConfChanged = () => {
            setKey(v => v + 1);
        }
    })

    return (
        <div className={"ContentPage"}>
            <header>
                <h2>R-Planet Wiki</h2>
                <SideBarContext.Consumer>
                    {({togglerSideBar, sideBar}) => (
                        <SideBarBtn togglerSideBar={togglerSideBar} sideBar={sideBar}/>
                    )}
                </SideBarContext.Consumer>
            </header>
            <ReactMarkdown
                key={key}
                children={p.data}
                skipHtml={true}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    a: it => p.modifier.transform(it)
                }}
            />
        </div>
    );
}
