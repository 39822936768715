import React, {createContext} from "react";
import "../App.css";
import { app, initAppContext } from "./AppContext";
import { ContentLoader } from "./ContentLoader";
import { ContentModifier } from "./ContentModifier";
import { ContentPage } from "./ContentPage";
import {NavigationPanel} from "./components/NavigationPanel";

console.clear();

initAppContext();

const loader = new ContentLoader();
const modifier = new ContentModifier();
export const SideBarContext = createContext({togglerSideBar: () => {}, sideBar: false});

interface AppSate
{
	pageData: string;
	viewSource: boolean;
	sideBar: boolean;
    togglerSideBar: () => void;
}

class AppRoot extends React.Component<{}, AppSate>
{
	constructor(props: {})
	{
		super(props);
		this.state = {
			pageData: "",
			viewSource: false,
			sideBar: false,
            togglerSideBar: this.toggleeSideBar
		};
		app.model.currentNode = app.resolveNodeByRoute();
		modifier.onLinkPressed = path => this.handleContentLink(path);
	}

	toggleeSideBar = () => {
		this.setState({sideBar: !this.state.sideBar});
	};

	componentDidMount()
	{
		console.log("[App] init");

		this.loadPage();

		window.onpopstate = () => {
			console.log("[App] onpopstate");
			app.model.currentNode = app.resolveNodeByRoute();
			this.loadPage();
		};
	}

	private handleContentLink(path: string)
	{
		const node = app.model.findByPath(path);
		if (node) {
			app.model.currentNode = node;
			this.onNodeSelected();
		} else {
			this.setState({
				pageData: loader.pageNotFound(path)
			});
		}
	}

	private loadPage()
	{
		document.title = app.getPageTitle();
		loader.load(app.model.currentNode)
			.then(pageData => this.setState({pageData}));
	}

	private onNodeSelected = () => {
		window.history.pushState({}, "", app.getPageUrl());
		this.loadPage();
	};

	private sourceBtn()
	{
		return <div style={{
			position: "fixed",
			right: "20px",
			top: "10px",
            zIndex: 2
		}}>
			<button
				style={{marginRight: "4px"}}
				onClick={
					() => this.setState({viewSource: !this.state.viewSource})
				}>
				View
			</button>

			<button
				onClick={
					() => window.open(app.getFileUrl())
				}>
				Open
			</button>
		</div>;
	}

	private sourcePage()
	{
		return <div className="SourcePage">
			<pre>
				{loader.source}
			</pre>
		</div>;
	}

	render()
	{
		return (
            <React.StrictMode>
                <div className="App">
					<SideBarContext.Provider value={this.state}>
						<NavigationPanel onNodeSelected={this.onNodeSelected}
										 onShow={this.state.sideBar}/>
                    <div className="content-panel">
                        <ContentPage data={this.state.pageData}
                                     modifier={modifier}
                        />
                        {app.devMode && this.sourceBtn()}
                        {this.state.viewSource && this.sourcePage()}
                    </div>
					</SideBarContext.Provider>
                </div>
            </React.StrictMode>

		);
	}
}

export default AppRoot;
