export const str = {

	fractions: {
		fr1: "Masterminds",
		fr2: "Warriors",
		fr3: "Renegades",
		uu: "Unique Units",
		nu: "Native Units",
		au: "Alien Units",
	},

	resources: {
		cp: "Caponium",
		nf: "Enefterium",
		wc: "Wecanite",
		wx: "Waxon"
	},

	unitTypes: {
		robot: "Robot",
		drone: "Drone",
		spy: "Spy",
		ram: "Ram",
		art: "Artillery",
		hacker: "Hacker",
		settler: "Settler"
	}
};