import { StrEnum } from "../enums/StrEnum";

export const EAppEnv = StrEnum("EAppEnv",
{
	dev: {},
	test: {},
	stage: {},
	prod: {},
});

export type TAppEnv = keyof typeof EAppEnv;

export interface IDBConf
{
	readonly url: string;
	readonly cert: string;
	readonly user: string;
	readonly passw: string;
}

export interface IServerConf
{
	readonly port: number;
	readonly externalUrl: string;
}

export interface ITokenData
{
	userId: number,
	adminHash: string,
	expiredAt: number,
}

export const EPlatform = StrEnum("EPlatform", {
	android: {},
	ios: {},
	web: {},
});

export type TPlatform = keyof typeof EPlatform;

export const ADMIN_NAME = "admin";
export const NOBODY_NAME = "nobody";
