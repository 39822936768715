//noinspection JSUnusedGlobalSymbols
import { StrEnum } from "../enums/StrEnum";

export const EBuildId = StrEnum("EBuildId",
{
	mine_cp: {},
	mine_nf: {},
	mine_wx: {},
	mine_wc: {},
	factory_cp: {},
	factory_nf: {},
	factory_wx: {},
	factory_wc: {},
	stor_ctl: {},
	stor_sec: {},
	base_dev: {},
	datacenter: {},
	land_ctl: {},
	land_unit: {},
	market: {},
	army_dev: {},
	army_ctl: {},
	army_up: {},
	army_shield: {},
	units_gnd: {},
	units_air: {},
	units_art: {},
	base_shield: {},
	trans_up: {},
	speed_up: {},
	artifactor: {},
});

export type TBuildId = keyof typeof EBuildId;

export const EBuildState = StrEnum("EBuildState",
{
	B: {},
	R: {},
	D: {},
});

export type TBuildState = keyof typeof EBuildState;

export const MiningBuildTypes: readonly TBuildId[] = [
	"mine_cp",
	"mine_nf",
	"mine_wx",
	"mine_wc",
];

export function isMiningBuildType(buildId: TBuildId)
{
	return MiningBuildTypes.includes(buildId);
}
