export type PartialRequired<T, K extends keyof T> = T & Required<Pick<T, K>>

export type TObjectKey = string | number | symbol;
export type TAnyObject = Record<TObjectKey, unknown>;

export function as<T>(obj: T): T
{
	return obj;
}

export function parseFloatOrDefault(value: unknown, defaultValue = 0): number
{
	const parsed = Number.parseFloat(String(value));
	return Number.isFinite(parsed) ? parsed : defaultValue;
}

export function parseIntOrDefault(value: unknown, defaultValue = 0): number
{
	const parsed = Number.parseInt(String(value));
	return Number.isFinite(parsed) ? parsed : defaultValue;
}

export function isInt(value: unknown): value is number
{
	return Number.isInteger(value);
}

export function isString(value: unknown): value is string
{
	return typeof value === "string";
}

export function isNotEmptyString(value: unknown): value is string
{
	return typeof value === "string"
	       && value.length > 0;
}

export function isObjectNotNull<T extends TAnyObject>(value: unknown): value is Exclude<T, null>
{
	return typeof(value) === "object" && value !== null;
}

export function tryGetField(obj: unknown, key: TObjectKey): unknown
{
	if (typeof(obj) === "object" && obj !== null)
		return (obj as TAnyObject)[key];
	else
		return undefined;
}

export function isDate(value: unknown): value is Date
{
	return value instanceof Date;
}

export function checked<T>(value: unknown,
                           predicate: (value: unknown) => value is T,
                           message = "invalid value"): T
{
	if (predicate(value))
		return value;

	throw new Error(message);
}

export function check(value: unknown, message: string): asserts value
{
	if (!value)
		throw new Error(message);
}

export function isoToDate(value: unknown, message = "invalid date"): Date
{
	if (typeof (value) !== "string")
		throw new Error(message);

	if (!value.endsWith("Z"))
		throw new Error(message);

	return new Date(value);
}

export function checkNever(_: never): never
{
	throw new Error("Didn't expect to get here");
}