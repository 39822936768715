export class GameConst
{
	static readonly BASE_MINE_CNT = 9;

	static readonly RPALNET_CHAT_ID = "c:rplanet";
	static readonly RPALNET_CHAT_NAME = "R-Planet";

	static readonly DEFAULT_HERO = {
		pic: "drake",
		name: "Drake",
	} as const;

	static readonly MAX_NFT_HERO_LEVEL = 200;
	static readonly MAX_DEFAULT_HERO_LEVEL = 50;

	static readonly FORBIDDEN_TRANSFER_DAYS = 5;

	static readonly PUSH_DELAY_S = 120;
	static readonly PUSH_INTERVAL_S = 60;

	//TOCLEAN: remove legacyUnitGames
	static readonly legacyUnitGames: readonly string[] = [
		"game0",
		"game1",
		"game2",
		"game3",
		"game4",
		"game5",
		"game6",
		"game7",
		"play_00",
	];

	static readonly SMALL_MAP_SCALES: number[] = [1.0, 4.0, 7.0];
	static readonly LARGE_MAP_SCALES: number[] = [1.1, 2.0, 7.0];
	static readonly NO_USER_ID: TUserId = -1;

	static readonly FINISH_DETACH_ASSETS_DELAY_S = 60;
	static readonly FINISH_GIVE_PRIZES_DELAY_S = 120;

}