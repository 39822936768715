import { BuildStruct } from "@src/common/interfaces";
import { BuildReqTO } from "./conf_dto";

export class BuildReq
{
	readonly entries: Immutable<BuildReqTO[]>;

	constructor(data: BuildReqTO[])
	{
		this.entries = data;
	}

	verify(buildings: BuildStruct[]): boolean
	{
		for (const req of this.entries) {
			const found = buildings.find(it => it.id === req.id &&
			                                   it.level >= req.level);
			if (!found)
				return false;
		}
		return true;
	}
}