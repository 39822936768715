import { StrEnum } from "@src/common";
import { ELocType, EResId, sumResources, TLocType, TResId, TResources } from "@src/common/interfaces";
import { ArrayUtil, check } from "@src/common/utils";
import { GameConst } from "./GameConst";

export class LocType
{
	static isDecor(locTypeId: string): boolean
	{
		return locTypeId[0] === "e";
	}

	static isBase(locTypeId: string): boolean
	{
		return locTypeId[0] === "b";
	}

	readonly id: TLocType;
	readonly resCnt: number = 0;
	readonly mines: TResources;
	readonly isBase: boolean;

	constructor(data: {id: TLocType, mines: TResources})
	{
		this.id = StrEnum.check(ELocType, data.id);
		this.isBase = LocType.isBase(this.id);
		this.mines = data.mines;
		this.resCnt = sumResources(data.mines);
	}

	get hasMines(): boolean
	{
		return this.resCnt > 0;
	}

	makeMinesSlots(): TResId[]
	{
		const resIds = Object.keys(EResId) as TResId[];

		ArrayUtil.shuffleSelf(resIds);

		const result: TResId[] = [];

		for (const resId of resIds) {
			const mineCnt = this.mines[resId];
			for (let i = 0; i < mineCnt; i++) {
				result.push(resId);
			}
		}

		check(result.length = GameConst.BASE_MINE_CNT, "invalid result");
		return result;
	}

	get isForPool()
	{
		//todo: get from map conf
		return this.id === "b0";
	}
}