import { TSeconds } from "@src/common";
import { BuildStruct, TBuildId, TBuildState, TResId, TResources } from "@src/common/interfaces";
import { check, MathUtil } from "@src/common/utils";
import { BuildReq } from "./BuildReq";
import { BuildLevelTO, BuildTypeTO } from "./conf_dto";

export class BuildLevel
{
	readonly resources: TResources;
	readonly buildTime: TSeconds;
	readonly droids: number;
	readonly value: number;

	constructor(data: BuildLevelTO)
	{
		this.resources = data.resources;
		this.buildTime = data.build_time as TSeconds;
		this.droids = data.droids;
		this.value = data.value;
	}

	getBuildTime(boostPercent: number): TSeconds
	{
		return MathUtil.subPercent_int(this.buildTime, boostPercent) as TSeconds;
	}
}

export class BuildType
{
	readonly id: TBuildId;
	readonly group: string;
	readonly rank: number;
	readonly maxCnt: number;
	readonly maxLevel: number;
	readonly buildReq: BuildReq;
	readonly minedResource: TResId | null;

	private readonly _levels: readonly BuildLevel[];

	constructor(data: BuildTypeTO)
	{
		this.id = data.id as TBuildId;
		this.group = data.group;
		this.rank = data.rank;
		this.maxCnt = data.max_cnt;
		this.maxLevel = data.max_level;
		this.buildReq = new BuildReq(data.build_req);
		this.minedResource = getMinedResource(this.id);
		this._levels = data.levels.map(it => new BuildLevel(it));
	}

	getLevel(level: number): BuildLevel
	{
		check(level >= 0 && level < this._levels.length, "invalid level");
		return this._levels[level];
	}

	//noinspection JSUnusedGlobalSymbols (used in wiki)
	findLevel(level: number): BuildLevel
	{
		return this._levels[level];
	}

	getAllLevels(): BuildLevel[]
	{
		return this._levels.slice();
	}

	makeBuild(slot: number,
	          level: number | "max" = 1,
	          state: TBuildState = "R"): BuildStruct
	{
		return {
			id: this.id,
			slot,
			level: level === "max"
				? this._levels.length - 1
				: level,
			state,
		};
	}
}

function getMinedResource(buildId: TBuildId): TResId | null
{
	switch (buildId) {
		case "mine_cp":
			return "cp";
		case "mine_nf":
			return "nf";
		case "mine_wx":
			return "wx";
		case "mine_wc":
			return "wc";
		default:
			return null;
	}
}

export function getBuildIdByResource(resId: TResId): TBuildId
{
	switch (resId) {
		case "cp":
			return "mine_cp";
		case "nf":
			return "mine_nf";
		case "wx":
			return "mine_wx";
		case "wc":
			return "mine_wc";
	}
}