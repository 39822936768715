import { StrEnum, TSeconds } from "@src/common";
import { EFraction, TBuildId, TFraction, TResources, TUnitId, TUnitType, UnitStruct } from "@src/common/interfaces";
import { MathUtil } from "@src/common/utils";
import { BuildReq } from "./BuildReq";
import { SettingsTO, UnitTypeTO } from "./conf_dto";

const fight_multipliers: number[] = [];

export class UnitType
{
	public static idComparer = (a: { id: TUnitId },
	                            b: { id: TUnitId }): number => (a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0;

	readonly id: TUnitId;
	readonly frId: TFraction;
	readonly name: TUnitType;
	readonly speed: number;
	readonly attack: number;
	readonly gndDefense: number;
	readonly airDefense: number;
	readonly consumption: number;
	readonly capacity: number;

	readonly devResources: TResources;
	readonly devRequires: BuildReq;
	readonly devTime: TSeconds;

	readonly upResources: TResources;
	readonly upTime: number;

	readonly prodResources: TResources;
	readonly prodBuildId: TBuildId;
	readonly prodTime: TSeconds;

	constructor(private readonly settings: Immutable<SettingsTO>,
	            data: UnitTypeTO)
	{
		this.id = data.id as TUnitId;
		this.frId = StrEnum.check(EFraction, data.fr_id);
		this.name = data.type;
		this.speed = data.speed;
		this.attack = data.attack;
		this.gndDefense = data.gnd_defense;
		this.airDefense = data.air_defense;
		this.consumption = data.wc_consum;
		this.capacity = data.capacity;

		this.devResources = data.dev_resources;
		this.devRequires = new BuildReq(data.dev_build_req);
		this.devTime = data.dev_time as TSeconds;

		this.upResources = data.up_resources;
		this.upTime = data.up_time;

		this.prodResources = data.prod_resources;
		this.prodBuildId = data.prod_build as TBuildId;
		this.prodTime = data.prod_time as TSeconds;
	}

	getProdResources(quantity: number): TResources
	{
		return {
			cp: Math.floor(this.prodResources.cp * quantity),
			nf: Math.floor(this.prodResources.nf * quantity),
			wx: Math.floor(this.prodResources.wx * quantity),
			wc: Math.floor(this.prodResources.wc * quantity),
		};
	}

	getMoveDuration(distance: number, speedBoost: number): TSeconds
	{
		const speed = MathUtil.addPercent_int(this.speed, speedBoost);
		return Math.floor(distance / speed * 3600) as TSeconds;
	}

	getAttack(level: number): number
	{
		return this.getProperty(this.attack, level);
	}

	getGndDefense(level: number): number
	{
		return this.getProperty(this.gndDefense, level);
	}

	getAirDefense(level: number): number
	{
		return this.getProperty(this.airDefense, level);
	}

	getIntelAttack(level: number): number
	{
		return this.name === "spy"
			? this.getProperty(this.settings.spy_attack, level)
			: 0;
	}

	getIntelDefense(level: number): number
	{
		return this.name === "spy"
			? this.getProperty(this.settings.spy_defense, level)
			: 0;
	}

	private getProperty(value: number, level: number): number
	{
		if (level <= 1)
			return value;

		let mult = fight_multipliers[level];
		if (mult === undefined)
			mult = fight_multipliers[level] = Math.pow(this.settings.unit_up_fight_mult, level - 1);

		return value * mult;
	}

	makeUnit(cnt = 1, level = 1): UnitStruct
	{
		return {id: this.id, level, cnt};
	}

	get isUpgradable(): boolean
	{
		return this.upTime > 0;
	}

	get isExpansionUnit(): boolean
	{
		return this.name === "settler" ||
		       this.name === "hacker";
	}

	get isAir()
	{
		return this.prodBuildId === "units_air";
	}
}