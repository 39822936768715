import { ObjectUtil } from "./object_util";

export * from "./array_util";
export * from "./game_util";
export * from "./secure_util";
export * from "./type_util";
export * from "./math_util";
export * from "./object_util";
export * from "./asset_util";

export const rmUndef = ObjectUtil.removeUndefined.bind(null);
