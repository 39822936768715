import { TSeconds } from "@src/common";
import { TAssetType } from "@src/common/interfaces";
import { MathUtil } from "@src/common/utils";

type TFixedReward =
	{ readonly kind: "resources" } |
	{ readonly kind: "asset", readonly templateName: string }

export class HeroQuestConf
{
	readonly min_duration_s = 4 * 60 as TSeconds;
	readonly max_duration_s = 6 * 60 as TSeconds;
	readonly initial_points = 3;

	readonly questAssetsTypes: readonly TAssetType[] = [
		"hero_revive",
		"hero_heal",
		"hero_redist",
	];

	readonly externalAssetChance = 0.01;

	private readonly _fixedRewards: TFixedReward[] = [
		{kind: "resources"},
		{kind: "asset", templateName: "monowheel_common"},
		{kind: "resources"},
		{kind: "resources"},
		{kind: "asset", templateName: "mixpad_rare"},
		{kind: "asset", templateName: "pill_common"},
		{kind: "resources"},
		{kind: "asset", templateName: "first_aid_kit_epic"},
		{kind: "resources"},
	];

	getFixedReward(num: number): TFixedReward | undefined
	{
		return this._fixedRewards[num];
	}

	get fixedRewardCnt(): number
	{
		return this._fixedRewards.length;
	}


	constructor()
	{
	}

	getDuration(): TSeconds
	{
		return MathUtil.randomInt(this.min_duration_s, this.max_duration_s) as TSeconds;
	}
}