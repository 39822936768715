import { TResources } from "@src/common/interfaces";
import React from "react";
import { ReactMarkdownProps } from "react-markdown/lib/ast-to-react";
import { BuildReq } from "../game/core/conf";
import { str } from "../resources/strings";
import { app } from "./AppContext";
import { SpeedSelector } from "./components/SpeedSelector";
import { formatDuration, formatNumber } from "./format-util";
import { WikiNode } from "./WikiModel";

let onClick: (e: React.MouseEvent, path: string) => void;

export class ContentModifier
{
	onLinkPressed: (path: string) => void = () => {};

	private refs = new Map<string, (key: string, value: string, parts: string[]) => JSX.Element>();

	//region [init]

	constructor()
	{
		const conf = app.conf;

		onClick = (e, path) => {
			this.onLinkPressed(path);
			e.preventDefault();
		};

		const refs = this.refs;

		refs.set("settings", this.settingsElement.bind(this));

		for (let unitType in str.unitTypes) {
			refs.set(unitType, this.unitTypeElement.bind(this));
		}

		for (let resId in str.resources) {
			refs.set(resId, this.resourceElement.bind(this));
		}

		for (let frId of conf.fractionTypes.keys()) {
			refs.set(frId, this.fractionElement.bind(this));
		}

		for (let buildId of conf.buildTypes.keys()) {
			refs.set(buildId, this.buildingElement.bind(this));
		}

		for (let unitId of conf.unitTypes.keys()) {
			refs.set(unitId, this.unitElement.bind(this));
		}

		/// UI controls
		refs.set("speed_selector", () => SpeedSelector());


		/// building props
		refs.set("droids", () => this.propertyElement("Droids", "prop-droids"));
		refs.set("build_time", () => this.propertyElement("Build time", "prop-time"));
		refs.set("max_level", () => this.propertyElement("Max level", "prop-level"));
		refs.set("art_rank", () => this.propertyElement("Artillery rank", "prop-art-rank"));

		/// unit props
		refs.set("attack", () => this.propertyElement("Attack", "prop-attack"));
		refs.set("air_defense", () => this.propertyElement("Air Defense", "prop-air-defense"));
		refs.set("gnd_defense", () => this.propertyElement("Ground Defense", "prop-gnd-defense"));
		refs.set("consumption", () => this.propertyElement("Consumption", "prop-consumption"));
		refs.set("spy_attack", () => this.propertyElement("Intel attack", "prop-spy-attack"));
		refs.set("spy_defense", () => this.propertyElement("Intel defense", "prop-spy-defense"));
		refs.set("capacity", () => this.propertyElement("Capacity", "prop-capacity"));
		refs.set("speed", () => this.propertyElement("Speed", "prop-speed"));
		refs.set("dev_time", () => this.propertyElement("Development time", "prop-time"));
		refs.set("prod_time", () => this.propertyElement("Production time", "prop-time"));
		refs.set("up_time", () => this.propertyElement("Upgrade time", "prop-time"));
	}

	//endregion

	//region [links]

	nodeLink(iconClass: string, node: WikiNode)
	{
		return <a className={`link-icon ${iconClass}`}
		          href={node.path}
		          onClick={e => onClick(e, node.path)}>
			{node.name}
		</a>;
	}

	pathLink(iconClass: string, title: string, path: string)
	{
		return <a className={`link-icon ${iconClass}`}
		          href={path}
		          onClick={e => onClick(e, path)}>
			{title}
		</a>;
	}

	contentLink(key: string, value: string)
	{
		const node = app.model.findById(key);
		if (!node)
			return invalidLink(key, value);

		return <a href={node.path}
		          onClick={e => onClick(e, node.path)}>
			{node.name}
		</a>;
	}

	externalLink(key: string, value: string)
	{
		return <a href={value} target="_blank" rel="noreferrer">{key}</a>;
	}

	//endregion

	unitTypeElement(key: string, value: string = "")
	{
		const name = str.unitTypes[key];
		return name
			? this.pathLink(`unit-${key}`, name, "/units")
			: invalidLink(key, value);
	}

	resourceElement(key: string, value: string)
	{
		if (value === "") {
			const name = str.resources[key];
			return name
				? this.pathLink(`res-${key}`, name, "/resources")
				: invalidLink(key, value);
		} else {
			return <span className={`link-icon res-${key}`}>{formatNumber(Number(value))}</span>;
		}
	}

	fractionElement(key: string, value: string)
	{
		const node = app.model.findById(key);
		return node
			? this.nodeLink(`fr-${key}`, node)
			: invalidLink(key, value);
	}

	buildingElement(key: string, value: string, parts: string[])
	{
		if (parts.length === 1) {
			const node = app.model.findById(key);
			return node
				? this.nodeLink("link-build", node)
				: invalidLink(key, value);
		}

		const conf = app.conf;
		const buildType = conf.buildTypes.get(parts[0]);
		if (!buildType)
			return invalidLink(key, value);

		const propName = parts[1];

		switch (propName) {
			case "build_req":
				return requirementsContainer(buildType.buildReq);
			case "max_level":
				return <span>{buildType.maxLevel}</span>;
			case "art_rank":
				return <span>{buildType.rank}</span>;
		}

		const buildLevel = buildType.findLevel(parseInt(value));
		if (!buildLevel)
			return invalidLink(key, value);

		switch (propName) {
			case "resources":
				return resourceContainer(buildLevel.resources);
			case "build_time":
				return durationValue(buildLevel.buildTime);
			case "droids":
				return <span>{buildLevel.droids}</span>;
			case "value":
				return <span>{formatNumber(buildLevel.value)}</span>;
		}

		return invalidLink(key, value);
	}

	unitElement(key: string, value: string, parts: string[])
	{
		const conf = app.conf;

		if (parts.length === 1) {
			const unitType = conf.unitTypes.get(key)!;
			const node = app.model.findById(key);
			return node
				? this.nodeLink(`unit-${unitType.name}`, node)
				: invalidLink(key, value);
		}

		const unitType = conf.unitTypes.get(parts[0]);
		if (!unitType)
			return invalidLink(key, value);

		const propName = parts[1];
		const unitLevel = Number(value) || 1;

		switch (propName) {
			case "type":
				return this.unitTypeElement(unitType.name);
			case "attack":
				return <span>{Number(unitType.getAttack(unitLevel)).toFixed(1)}</span>;
			case "air_defense":
				return <span>{Number(unitType.getAirDefense(unitLevel)).toFixed(1)}</span>;
			case "gnd_defense":
				return <span>{Number(unitType.getGndDefense(unitLevel)).toFixed(1)}</span>;
			case "capacity":
				return <span>{unitType.capacity}</span>;
			case "wc_consum":
				return wcConsumption(unitType.consumption);
			case "prod_res":
				return resourceContainer(unitType.prodResources);
			case "prod_time":
				return durationValue(unitType.prodTime);
			case "speed":
				return <span>{unitType.speed}</span>;
			case "dev_req":
				return requirementsContainer(unitType.devRequires);
			case "dev_res":
				return resourceContainer(unitType.devResources);
			case "dev_time":
				return durationValue(unitType.devTime);
			case "up_res":
				return resourceContainer(app.conf.getUnitUpgradeCost(unitType, unitLevel));
			case "up_time":
				return durationValue(app.conf.getUnitUpgradeTime(unitType, unitLevel));
		}

		return invalidLink(key, value);
	}

	propertyElement(name: string, iconClass: string)
	{
		return <span className={`link-icon ${iconClass}`}>{name}</span>;
	}

	settingsElement(key: string, value: string, parts: string[])
	{
		const settingName = parts[1];
		const settingValue = app.conf.settings[settingName];

		if (typeof (settingValue) === "number")
			return <span>{settingValue}</span>;

		return invalidLink(key, value);
	}

	transform(it: React.ComponentPropsWithoutRef<"a"> & ReactMarkdownProps): JSX.Element
	{
		const textNode = it.node.children[0] ?? {};
		const key = textNode["value"] ?? "";
		const value = it.href ?? "";
		const parts = key.split(".");

		const transform = this.refs.get(parts[0]);
		if (transform)
			return transform(key, value, parts);

		if (!value)
			return this.contentLink(key, value);

		if (value.startsWith("http"))
			return this.externalLink(key, value);

		return invalidLink(key, value);
	}
}

function invalidLink(key: string, value?: string)
{
	return <a className={"link-invalid"} href={key}>
		{`[${key}]`}{value ? `(${value})` : ""}
	</a>;
}

function resourceContainer(data: TResources)
{
	const keys = Object.keys(data).filter(key => data[key] > 0);

	return <div className="res-container">
		{
			keys.map(key => {
				return <div key={key} className="res-item">
					{/*<div className={`res-devider`}></div>*/}
					<div className={`res-icon res-icon-${key}`}></div>
					<span >{formatNumber(data[key])}</span>
				</div>
			})
		}
	</div>;
}

function requirementsContainer(data: BuildReq)
{
	return (
		<div className="req-container">{
			data.entries.map(it => {
				const node = app.model.findById(it.id);
				if (!node)
					return invalidLink(it.id);
				return (
					<a key={it.id}
					   href={node.path}
					   className="link-icon link-build"
					   onClick={e => onClick(e, node.path)}
					>
						{node.name}
						<span className="req-level">{it.level}</span>
					</a>
				);
			})
		}</div>
	);
}

function wcConsumption(value: number)
{
	return <div className="res-conteiner">
		<span className={"res-icon res-wc"}></span>
		<span className="res-label">{value}</span>
	</div>;
}

function durationValue(value: number)
{
	return <span className="val-duration">
		{formatDuration(value)}
	</span>;
}
