import { ChangeEvent, useState } from "react";
import { TGameSpeed } from "../../common/interfaces";
import { app } from "../AppContext";

export function SpeedSelector()
{
	const [selectedOption, setSelectedOption] = useState(app.gameSpeed);

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const gameSpeed = event.target.value as TGameSpeed;
		setSelectedOption(gameSpeed);
		app.setGameSpeed(gameSpeed);
	};

	return (
		<select value={selectedOption} onChange={handleChange}>
			<option value="normal">Game speed: Normal</option>
			<option value="fast_1">Game speed: Medium (x3)</option>
			<option value="fast_2">Game speed: Fast (x5)</option>
		</select>
	);
}