import { check } from "./type_util";

export class MathUtil
{
	static clamp(value: number, min: number, max: number): number
	{
		if (value < min)
			return min;
		if (value > max)
			return max;
		return value;
	}

	static subPercent_int(value: number, percent: number)
	{
		check(value >= 0, "invalid_arg[value]");
		check(percent >= 0, "invalid_arg[percent]");
		check(percent <= 100, "invalid_arg[percent]");
		return Math.floor(value * (1 - 0.01 * percent));
	}

	static addPercent_int(value: number, percent: number)
	{
		check(value >= 0, "invalid_arg[value]");
		check(percent >= 0, "invalid_arg[percent]");
		return Math.floor(value * (1 + 0.01 * percent));
	}

	static addPercent_float(value: number, percent: number)
	{
		check(value >= 0, "invalid_arg[value]");
		check(percent >= 0, "invalid_arg[percent]");
		return value * (1 + 0.01 * percent);
	}

	static randomInt(from: number, to: number): number
	{
		const rnd = Math.random() * (to - from);
		return Math.round(from + rnd);
	}

}

