import { StrEnum } from "../enums/StrEnum";

export interface IMapTypeParams
{
	readonly size: int;
}

export const EMapType = StrEnum("EMapType", {
	small: {},
	large: {},
});

export type TMapType = keyof typeof EMapType;

export const MapTypeConf: Record<TMapType, IMapTypeParams> = {
	small: {
		size: 57,
	},
	large: {
		size: 100,
	},
};

export const EMapSector = StrEnum("EMapSector", {
	s1: {},
	s2: {},
	s3: {},
	s4: {},
});

export type TMapSector = keyof typeof EMapSector;
