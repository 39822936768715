import { WikiNode } from "./WikiModel";

export class ContentLoader
{
	public source: string = "";

	async load(node: WikiNode): Promise<string>
	{
		return this.fetchContent(node.path);
	}

	async fetchContent(path: string): Promise<string>
	{
		if (!path.startsWith("/"))
			path = "/" + path;

		const url = `/wiki${path}.md`;
		console.log("[ContentLoader]", "load:", url);

		let response: Response;
		try {
			response = await window.fetch(url);
		} catch (e: any) {
			return this.pageLoadingError(url, e);
		}

		if (!response.ok)
			return this.pageNotFound(url);

		let text: string;
		try {
			text = await response.text();
		} catch (e: any) {
			return this.pageLoadingError(url, e);
		}

		this.source = text;

		if (text.startsWith("<!"))
			return this.pageNotFound(url);

		/// replace '[...]' with '[...]()'
		return text.replace(/(\[[\w.]*])([^(])/g, "$1()$2");
	}

	pageNotFound(url: string)
	{
		return `### Page not found\n\n\`${url}\``;
	}

	pageLoadingError(url: string, error: any)
	{
		return `### Error loading page\n\n\`${url}\`\n\n${error.message}`;
	}
}



