import { StrEnum } from "../enums/StrEnum";
import { TSeconds, TTime } from "../time";

export const EUnitType = StrEnum("EUnitType", {
	robot: {},
	drone: {},
	ram: {},
	art: {},
	spy: {},
	hacker: {},
	settler: {},
});

export type TUnitType = keyof typeof EUnitType;

//noinspection JSUnusedGlobalSymbols
export const EUnitId = StrEnum("EUnitId", {
	ma01: {},
	ma02: {},
	ma03: {},
	ma04: {},
	ma05: {},
	ma06: {},
	ma07: {},
	ma08: {},
	ma09: {},
	ma10: {},
	wa01: {},
	wa02: {},
	wa03: {},
	wa04: {},
	wa05: {},
	wa06: {},
	wa07: {},
	wa08: {},
	wa09: {},
	wa10: {},
	re01: {},
	re02: {},
	re03: {},
	re04: {},
	re05: {},
	re06: {},
	re07: {},
	re08: {},
	re09: {},
	re10: {},
	uu01: {},
	uu02: {},
	uu03: {},
	nu01: {},
	nu02: {},
	nu03: {},
	nu04: {},
	nu05: {},
	nu06: {},
	nu07: {},
	nu08: {},
	nu09: {},
	nu10: {},
	au01: {},
	au02: {},
	au03: {},
	au04: {},
	au05: {},
	au06: {},
	au07: {},
	au08: {},
	au09: {},
	au10: {},
});

export type TUnitId = keyof typeof EUnitId;

export interface TripHeroStruct
{
	readonly name: string;
	readonly level: number;
}

export interface UnitStruct
{
	readonly id: TUnitId;
	readonly cnt: number;
	readonly level: number;
}

export type MutableUnitStruct = Compute<
	PartialMutable<UnitStruct, "cnt" | "level">
>

export interface IUnitCreate
{
	readonly id: TUnitId;
	readonly cnt: number;
}

export interface UnitSpanStruct
{
	readonly unit_id: TUnitId;
	readonly task_id: string;     // Related task ID
	readonly prod_time: TSeconds; // One unit production time
	readonly ts: TTime;           // Last applied timestamp
	readonly cnt: number;         // Quantity of initial units
	readonly done: number;        // Quantity of applied units
	readonly slot: number;        // Related build slot
}

export type MutableSpanStruct = PartialMutable<UnitSpanStruct,
	| "prod_time"
	| "ts"
	| "cnt"
	| "done"
>


export interface IUnitCnt
{
	readonly id: TUnitId;
	readonly cnt: number;
}

//toclean: remove makeUnit
export interface IUnitProd
{
	readonly id: TUnitId;
	readonly cnt: number;
	readonly num: number;
}

export const EActionType = StrEnum("EActionType", {
	settle: {},
	support: {},
	battle: {},
	capture: {},
	spy: {},
});

export type TActionType = keyof typeof EActionType;

export const ECombatType = StrEnum("ECombatType", {
	attack: {},
	defense: {},
});

export type TCombatType = keyof typeof ECombatType;