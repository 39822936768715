export type TTime = Nominal<number, "TTime"> | 0;
export type TMillis = Nominal<number, "TMillis"> | 0;
export type TSeconds = Nominal<number, "TSeconds"> | 0;
export type TMinutes = Nominal<number, "TMinutes"> | 0;
export type THours = Nominal<number, "THours"> | 0;
export type TDays = Nominal<number, "TDays"> | 0;

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;
const MS_IN_HOUR = 60 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * MS_IN_HOUR;

const MAX_DATE = new Date(8640000000000000);

class Time
{
	private _frozenValue?: TTime;

	now(): TTime
	{
		return this._frozenValue ?? Date.now() as TTime;
	}

	cast(value: number): TTime
	{
		return (value % 1) === 0
			? value as TTime
			: Math.floor(value) as TTime;
	}

	freeze(value?: TTime): TTime
	{
		this._frozenValue = value ?? this.now();
		return this._frozenValue;
	}

	unfreeze()
	{
		this._frozenValue = undefined;
	}

	dropMillis(value: TTime): TTime
	{
		return Math.floor(value / MS_IN_SECOND) * MS_IN_SECOND as TTime;
	}

	addMillis(value: TTime, millis: number): TTime
	{
		return value + millis as TTime;
	}

	addSeconds(value: TTime, seconds: number): TTime
	{
		return Math.round(value + seconds * MS_IN_SECOND) as TTime;
	}

	addMinutes(value: TTime, minutes: number): TTime
	{
		return Math.round(value + minutes * MS_IN_MINUTE) as TTime;
	}

	addHours(value: TTime, hours: number): TTime
	{
		return Math.round(value + hours * MS_IN_HOUR) as TTime;
	}

	addDays(value: TTime, days: number): TTime
	{
		return Math.round(value + days * MS_IN_DAY) as TTime;
	}

	toSeconds(value: TTime): TSeconds
	{
		return value / MS_IN_SECOND as TSeconds;
	}

	toMinutes(value: TTime): TMinutes
	{
		return value / MS_IN_MINUTE as TMinutes;
	}

	toHours(value: TTime): THours
	{
		return value / MS_IN_HOUR as THours;
	}

	toDays(value: TTime): TDays
	{
		return value / MS_IN_DAY as TDays;
	}

	secondsNow(): TSeconds
	{
		return Math.floor(this.now() / MS_IN_SECOND) as TSeconds;
	}

	dateNow(): Date
	{
		return new Date(this.now());
	}

	fromDate(date: Date): TTime
	{
		return date.getTime() as TTime;
	}

	makeMeter(): TimeMeter
	{
		return new TimeMeter();
	}

	secondsToMillis(value: number): TMillis
	{
		return Math.floor(value * MS_IN_SECOND) as TMillis;
	}

	minutesToMillis(value: number): TMillis
	{
		return Math.floor(value * MS_IN_MINUTE) as TMillis;
	}

	hoursToMillis(value: number): TMillis
	{
		return Math.floor(value * MS_IN_HOUR) as TMillis;
	}

	daysToMillis(value: number): TMillis
	{
		return Math.floor(value * MS_IN_DAY) as TMillis;
	}

	get maxTime(): TTime
	{
		return MAX_DATE.getTime() as TTime;
	}

	get isFrozen(): boolean
	{
		return this._frozenValue !== undefined;
	}

}

class TimeMeter
{
	readonly startTime = time.now();

	get elapsed(): TMillis
	{
		return time.now() - this.startTime as TMillis;
	}

	toString()
	{
		return `${this.elapsed / 1000}s`;
	}
}

export const time = new Time();
