import { StrEnum } from "../enums/StrEnum";
import { TResId, TResources } from "./game_types";
import { TUnitId } from "./unit_types";

export const EAssetChain = StrEnum("EAssetChain", {
	wax: {},
	bnb: {},
	none: {},
	any: {},
});

export type TAssetChain = keyof typeof EAssetChain;
export type TSystemChain = Exclude<TAssetChain, "none">;
export type TGameChain = Extract<TAssetChain, "none">;

export const EAssetRarity = StrEnum("EAssetRarity", {
	common: {},
	uncommon: {},
	rare: {},
	epic: {},
	legendary: {},
	mythic: {},
});

export type TAssetRarity = keyof typeof EAssetRarity;

export const EAssetState = StrEnum("EAssetState", {
	ready: {},
	used: {},
	incoming: {},
	outgoing: {},
	burning: {},
});

export type TAssetState = keyof typeof EAssetState;

export const EAssetType = StrEnum("EAssetType", {
	land: {},
	robots: {},
	resources: {},
	finish_upgrade: {},
	protection: {},
	build_slot: {},
	hero: {},
	hero_stuff: {},
	hero_revive: {},
	hero_heal: {},
	hero_redist: {},
	hero_quest: {},
	pack: {},
	unknown: {},
});

export type TAssetType = keyof typeof EAssetType;

export const EBodyPart = StrEnum("EBodyPart", {
	none: {},
	head: {},
	left_hand: {},
	right_hand: {},
	body: {},
	legs: {},
	transport: {},
	device: {},
});

export type TBodyPart = keyof typeof EBodyPart;

export interface IChainAssetData
{
	name: string;
	img: string;
	quantity?: number,
	rarity?: string,
	mineral?: string,
	base_rate?: number,
	level?: number,
	duration?: number,
}

export interface IGameAssetData
{
	/// common
	readonly rarity?: TAssetRarity,
	readonly level?: number,
	readonly res_id?: TResId | "unknown",
	readonly mine_boost?: number,
	readonly unit_id?: TUnitId | "unknown",
	readonly quantity?: number,
	readonly resources?: TResources,
	readonly duration?: number,

	/// hero
	readonly health_rec?: number,
	readonly speed?: number,
	readonly force?: number,
	readonly ai_rate?: number,
	readonly native_cap?: number,

	readonly body_part?: TBodyPart,
	readonly unit_attack?: number,
	readonly unit_defense?: number,
	readonly unit_speed?: number,
}

export interface IAssetDef
{
	readonly id: string,
	readonly asset_name: string,
	readonly template_name: string,
	readonly image: string,
	readonly type: TAssetType,
	readonly gameData: IGameAssetData;
}

export interface AssetStruct
{
	readonly id: string,
	readonly chain: TAssetChain,
	readonly name: string,
	readonly img: string
	readonly data: IGameAssetData,
}
