import { TSeconds } from "@src/common";
import {
	BuildStruct,
	EBuildState,
	EFraction,
	ELocType,
	GameSpeedConf, IGameParams,
	IGameSpeedParams,
	ILocInit,
	isMiningBuildType,
	IUnitCnt,
	makeResources,
	TActionType,
	TBuildId,
	TFraction,
	TGameType,
	TLocType,
	TPosition,
	TResources,
	TUnitId,
	TUnitType,
	UnitStruct,
} from "@src/common/interfaces";
import { ArrayUtil, check, MathUtil } from "@src/common/utils";
import deepFreeze from "deep-freeze";
import sha1 from "sha1";
import { TPushGroup } from "../interfaces";
import { BuildLevel, BuildType } from "./BuildType";
import { ActionUnitTypesTO, GameConfTO, HeroPropConf, SettingsTO } from "./conf_dto";
import { FractionType } from "./FractionType";
import { GameAssingConf } from "./GameAssingConf";
import { GameConst } from "./GameConst";
import { HeroQuestConf } from "./HeroQuestConf";
import { IConfSource } from "./interfaces";
import { LocType } from "./LocType";
import { UnitType } from "./UnitType";

export class GameConf
{
	readonly settings: Immutable<SettingsTO>;
	readonly fractionTypes: ReadonlyMap<string, FractionType>;
	readonly locTypes: ReadonlyMap<string, LocType>;
	readonly buildTypes: ReadonlyMap<string, BuildType>;
	readonly unitTypes: ReadonlyMap<string, UnitType>;

	readonly actionUnitTypes: Record<TActionType, TUnitType[]>;

	readonly confData: GameConfTO;
	readonly confHash: string;
	readonly mapData: readonly ILocInit[];
	readonly assignments: GameAssingConf;
	readonly quests: HeroQuestConf;

	readonly aliens: AlienConf;

	readonly showLevelInReport = false;
	readonly rclTimeStep_s = 60 as TSeconds; // min time step for recycling
	readonly speedParams: IGameSpeedParams;
	readonly gameParams: IGameParams;

	readonly defaultPushGroups: Immutable<Record<TPushGroup, boolean>> = Object.freeze({
		none: false,
		bases: true,
		market: true,
		trip: true,
		combat: true,
		chat: true,
	});

	constructor(gameId: string, source: IConfSource)
	{
		this.confData = this.makeConfData(gameId, source);
		this.gameParams = source.gameParams;
		this.speedParams = GameSpeedConf[source.gameParams.game_speed];
		this.adjustSpeedParams(this.confData, this.speedParams);
		this.confHash = sha1(JSON.stringify(this.confData));

		this.mapData = source.mapData;
		this.assignments = new GameAssingConf(source.assignData);
		this.quests = new HeroQuestConf();

		this.settings = this.confData.settings;

		const lt = this.locTypes = new Map<TLocType, LocType>();
		lt.set("e", new LocType({id: "e", mines: {cp: 0, nf: 0, wx: 0, wc: 0}}));
		this.confData.locations.forEach(it => lt.set(it.id, new LocType(it)));

		const ft = this.fractionTypes = new Map<string, FractionType>();
		this.confData.fractions.forEach(it => ft.set(it.id, new FractionType(it)));

		const bt = this.buildTypes = new Map<string, BuildType>();
		this.confData.buildings.forEach(it => bt.set(it.id, new BuildType(it)));

		const ut = this.unitTypes = new Map<string, UnitType>();
		this.confData.units.forEach(it => ut.set(it.id, new UnitType(this.settings, it)));

		this.actionUnitTypes = this.confData.actionUnitTypes;

		this.aliens = new AlienConf(this);
	}

	private makeConfData(gameId: string, source: IConfSource): GameConfTO
	{
		const gameConf = source.baseConfData;
		const gameParams = source.gameParams;

		const mapMaxCoord = ArrayUtil.max(source.mapData, it => it.x);
		const mapSize = mapMaxCoord + 1;
		const mapScales = mapSize < 60
			? GameConst.SMALL_MAP_SCALES
			: GameConst.LARGE_MAP_SCALES;

		return {
			settings: {
				game_id: gameId,
				game_name: gameParams.game_name,
				game_type: gameParams.game_type,

				map_scales: mapScales,
				map_icon_scale_mult: 0.5,

				long_press_ms: 1000,
				legacy_unit_prod: GameConst.legacyUnitGames.includes(gameId), //TOCLEAN: remove

				game_end_players: gameConf.settings.ffa_end_players, //TODO: rename

				...gameConf.settings,
			},
			fractions: gameConf.fractions,
			locations: gameConf.locations,
			buildings: gameConf.buildings,
			units: gameConf.units,

			actionUnitTypes: this.makeActionUnitTypes(),
		};
	}

	private adjustSpeedParams(conf: GameConfTO, speedParams: IGameSpeedParams)
	{
		function adjustHeroProp(prop: HeroPropConf, mult: float): HeroPropConf
		{
			return {
				init: Math.round(prop.init * mult),
				mult: prop.mult * mult,
				max_points: prop.max_points,
			};
		}

		const settings = conf.settings;

		settings.hero_speed = adjustHeroProp(settings.hero_speed, speedParams.speed_mult);
		settings.hero_mine_rate = adjustHeroProp(settings.hero_mine_rate, speedParams.mine_rate_mult);

		for (const unit of conf.units) {
			unit.prod_time = Math.round(unit.prod_time * speedParams.prod_time_mult);
			unit.up_time = Math.round(unit.up_time * speedParams.prod_time_mult);
			unit.speed = Math.round(unit.speed * speedParams.speed_mult);
		}

		for (const build of conf.buildings) {
			for (const level of build.levels) {
				level.build_time = Math.round(level.build_time * speedParams.build_time_mult);

				if (isMiningBuildType(build.id))
					level.value = Math.round(level.value * speedParams.mine_rate_mult);
			}
		}
	}

	private makeActionUnitTypes(): ActionUnitTypesTO
	{
		return {
			settle: ["settler"],
			support: ["robot", "drone", "ram", "art", "spy", "hacker", "settler"],
			battle: ["robot", "drone", "ram", "art", "hacker"],
			capture: ["robot", "drone", "ram"],
			spy: ["spy"],
		};
	}

	getLevelXP(level: number)
	{
		return heroLevelXP[level] ?? heroLevelXP[heroLevelXP.length - 1];
	}

	//region LOCATIONS

	getLocType(id: TLocType | string): LocType
	{
		const type = this.locTypes.get(id);
		check(type, `LocType(id=${id}) not found`);
		return type;
	}

	getFraction(id: TFraction | string): FractionType
	{
		const type = this.fractionTypes.get(id);
		check(type, `FractionType(id=${id}) not found`);
		return type;
	}

	makeDefaultResources(): TResources
	{
		return makeResources(this.settings.default_resources);
	}

	makeInitilaResources(): TResources
	{
		return makeResources(this.settings.initial_resources);
	}

	getAroundPositions(pos: TPosition, minDistance: number, maxDistance: number): TPosition[]
	{
		const result: TPosition[] = [];

		if (minDistance === 0) {
			result.push({
				x: pos.x,
				y: pos.y,
			});
			minDistance = 1;
		}

		for (let d = minDistance; d <= maxDistance; d++) {

			for (let i = 0; i < d * 2; i++) {

				/// left-top row
				result.push({
					x: pos.x - 2 * d + i,
					y: pos.y + i,
				});

				/// right-top row
				result.push({
					x: pos.x + i,
					y: pos.y + 2 * d - i,
				});

				/// right-bottom
				result.push({
					x: pos.x + 2 * d - i,
					y: pos.y - i,
				});

				/// left-bottom
				result.push({
					x: pos.x - i,
					y: pos.y - 2 * d + i,
				});
			}
		}

		return result;
	}

	//endregion

	//region BUILDINGS

	makeInitialBuildings(): BuildStruct[]
	{
		let slot = this.minBuildSlot;
		return this.settings.initial_buildings.map(it => ({
			id: it.id,
			level: it.level,
			slot: slot++,
			state: EBuildState.R,
		}));
	}

	makeDefaultBuildings(): BuildStruct[]
	{
		return [{
			id: "base_dev",
			level: 1,
			slot: this.minBuildSlot,
			state: EBuildState.R,
		}];
	}

	getBuildType(id: TBuildId): BuildType
	{
		const type = this.buildTypes.get(id);
		check(type, `BuildType(id=${id}) not found`);
		return type;
	}

	getBuildLevel(building: BuildStruct): BuildLevel
	{
		return this.getBuildType(building.id).getLevel(building.level);
	}

	get minBuildSlot(): number
	{
		return GameConst.BASE_MINE_CNT;
	}

	get maxBuildSlot(): number
	{
		return GameConst.BASE_MINE_CNT + this.settings.build_slots_max - 1;
	}

	findBuilding(condition: Predicate<BuildType>): BuildType | null
	{
		for (const it of this.buildTypes.values()) {
			if (condition(it))
				return it;
		}
		return null;
	}

	isUnitProdBuilId(id: TBuildId): boolean
	{
		return id === "land_unit" ||
		       id === "units_gnd" ||
		       id === "units_air" ||
		       id === "units_art";
	}

	//endregion

	//region MARKET

	isRateValid(supply: number, demand: number): boolean
	{
		const rateMin = this.settings.market_rate_min;
		const rateMax = this.settings.market_rate_max;
		const rateStep = this.settings.market_rate_step;

		if (demand < Math.round(supply * rateMin))
			return false;

		if (demand > Math.round(supply * rateMax))
			return false;

		/**
		 * ensures that demand == round(supply * rate)
		 * where rate == rateMin + i * rateStep
		 */
		const demandStep = supply * rateStep;
		const stepsCnt = Math.round((demand - supply * rateMin) / demandStep);
		const rate = rateMin + rateStep * stepsCnt;

		return demand === Math.round(supply * rate);
	}

	//endregion

	//region UNITS

	getUnitType(id: TUnitId): UnitType
	{
		const type = this.unitTypes.get(id);
		check(type, `UnitType(id=${id}) not found`);
		return type;
	}

	findUnitTypes(predicate: (it: UnitType) => boolean): UnitType[]
	{
		const result: UnitType[] = [];
		for (const it of this.unitTypes.values()) {
			if (predicate(it))
				result.push(it);
		}
		return result;
	}

	findOneUnitType(predicate: (it: UnitType) => boolean): UnitType | null
	{
		for (const it of this.unitTypes.values()) {
			if (predicate(it))
				return it;
		}
		return null;
	}

	getUnitUpgradeCost(unitType: UnitType, level: number): TResources
	{
		const costMult = Math.pow(this.settings.unit_up_cost_mult, level - 1);
		return {
			cp: Math.floor(unitType.upResources.cp * costMult),
			nf: Math.floor(unitType.upResources.nf * costMult),
			wx: Math.floor(unitType.upResources.wx * costMult),
			wc: Math.floor(unitType.upResources.wc * costMult),
		};
	}

	getUnitUpgradeTime(unitType: UnitType, level: number): TSeconds
	{
		const timeMult = Math.pow(this.settings.unit_up_time_mult, level - 1);
		return Math.floor(unitType.upTime * timeMult) as TSeconds;
	}

	getUnitProdTime(unitType: UnitType, building: BuildStruct): TSeconds
	{
		check(building.id === unitType.prodBuildId, "build_id does not match unitType");
		check(building.level > 0, "level cannot be 0");
		const buildLevel = this.getBuildType(building.id).getLevel(building.level);
		return MathUtil.subPercent_int(unitType.prodTime, buildLevel.value) as TSeconds;
	}

	getMinSpeed(units: readonly UnitStruct[]): number
	{
		check(units.length > 0, "units cannot be empty");
		let min: number = Number.MAX_VALUE;
		for (const it of units) {
			const unitType = this.getUnitType(it.id);
			if (unitType.speed < min)
				min = unitType.speed;
		}
		return min;
	}

	makeDefaultUnits(frId: string): UnitStruct[]
	{
		const result: UnitStruct[] = [];
		for (const it of this.unitTypes.values()) {
			if (it.frId === frId && it.devTime === 0) {
				result.push({
					id: it.id,
					cnt: 0,
					level: 1,
				});
			}
		}
		return result;
	}

	verifyUnits(action: TActionType, units: IUnitCnt[]): boolean
	{
		const validTypes = this.actionUnitTypes[action];
		const maxCnt = action === "settle" ? 1 : Number.MAX_SAFE_INTEGER;

		for (const unit of units) {
			const unitType = this.getUnitType(unit.id);
			if (!validTypes.includes(unitType.name))
				return false;
			if (unit.cnt < 1 || unit.cnt > maxCnt)
				return false;
		}

		return true;
	}

	//endregion

	//region GAME

	get playerCanLose()
	{
		return this.gameType !== "aliens";
	}

	get hasAlienBases()
	{
		return this.gameType === "aliens";
	}

	get gameType(): TGameType
	{
		return this.settings.game_type;
	}

	get gameName(): string
	{
		return this.settings.game_name;
	}

	//endregion
}

const heroLevelXP = makeHeroLevelXP();

function makeHeroLevelXP(): readonly number[]
{
	/// https://docs.google.com/spreadsheets/d/1fUS8HRYy9Rl1pabPpuzCZkGw4vYhaHTOU5AV_QpOyXk
	const xps = [0, 0];
	for (let i = 2; i <= GameConst.MAX_NFT_HERO_LEVEL; i++) {
		xps[i] = xps[i - 1] + (i - 1) * 50;
	}
	return xps;
}

export class AlienConf
{
	readonly playerId = 0;
	readonly playerName = "Zorah";
	readonly fraction = EFraction.fr4;
	readonly alliance = "[Ahnangs]";
	readonly locType = ELocType.b3;
	readonly minesLevel = 10;
	readonly invasionStartLevel;
	readonly locSprite = ":ahnangs1_c";

	readonly trips_in_attack = 10;

	readonly spaceLoc = deepFreeze({
		id: "void",
		name: "[Armada]",
		x: -1000,
		y: -1000,
	});

	constructor(private readonly gameConf: GameConf)
	{
		this.invasionStartLevel = 1;
	}

	readonly invasion = deepFreeze({
		duration_h: 24,
		attack_delay_h: 12,
		trip_duration_m: 3 * 60,
	});

	makeBaseName(locId: string)
	{
		const suffix = locId.replace(/[0-9:]/g, "");
		const name = this.alliance
			.replace("[", "")
			.replace("]", "");
		return suffix
			? name + ":" + suffix
			: name;
	}

	makeInvasionResources(sequenceNum: number): TResources
	{
		const initialAmount = 200;
		const sequenceMult = Math.pow(1.08, Math.min(sequenceNum, 50));

		function makeAmount(): number
		{
			const randMult = 15 + 5 * Math.random();
			return Math.round(initialAmount * randMult * sequenceMult);
		}

		return {
			cp: makeAmount(),
			nf: makeAmount(),
			wx: makeAmount(),
			wc: makeAmount(),
		};
	}

	makeInvasionUnits(sequenceNum: number): UnitStruct[]
	{
		const initialUnits: { id: TUnitId, cnt: number }[] = [
			{id: "au01", cnt: 10},  // robot
			{id: "au02", cnt: 8},   // robot
			{id: "au03", cnt: 6},   // robot
			{id: "au05", cnt: 3},   // drone
			{id: "au06", cnt: 2},   // drone
			{id: "au07", cnt: 0.5}, // ram
			{id: "au08", cnt: 0.3}, // art
		];

		const sequenceMult = Math.pow(1.17, Math.min(sequenceNum, 50));
		const result: UnitStruct[] = [];

		ArrayUtil.shuffleSelf(initialUnits);
		const unitQnt = Math.round(2 + 3 * Math.random());

		for (let i = 0; i < unitQnt; i++) {
			const unit = initialUnits[i];
			const randMult = 1 + 2 * Math.random();
			const randCnt = Math.round(unit.cnt * randMult * sequenceMult / 10);
			result.push({
				id: unit.id,
				level: 1,
				cnt: Math.max(randCnt, 1),
			});
		}

		return result.sort((a, b) =>
			(a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0,
		);
	}

	private readonly buildingsDef: { id: TBuildId, level: number }[] = [
		{id: "base_dev", level: 20},
		{id: "army_ctl", level: 20},
		{id: "stor_ctl", level: 20},
		{id: "stor_ctl", level: 20},
		{id: "stor_ctl", level: 20},
		{id: "stor_ctl", level: 20},
		{id: "stor_ctl", level: 20},
		{id: "army_shield", level: 20},
		{id: "land_ctl", level: 20},
		{id: "land_unit", level: 20},
		{id: "datacenter", level: 20},
		{id: "army_dev", level: 20},
		{id: "army_up", level: 20},
		{id: "factory_wc", level: 5},
		{id: "units_gnd", level: 20},
		{id: "units_gnd", level: 20},
		{id: "units_air", level: 20},
		{id: "units_air", level: 20},
		{id: "base_shield", level: 20},
	];

	makeInitialBuildings(): BuildStruct[]
	{
		const buildings: BuildStruct[] = [];

		const locType = this.gameConf.getLocType(this.locType);
		const resIds = locType.makeMinesSlots();
		let mineSlot = 0;

		for (const resId of resIds) {
			buildings.push({
				id: `mine_${resId}`,
				level: this.minesLevel,
				slot: mineSlot++,
				state: EBuildState.R,
			});
		}

		let buildSlot = this.gameConf.minBuildSlot;
		for (const it of this.buildingsDef) {
			buildings.push({
				id: it.id,
				level: it.level,
				slot: buildSlot++,
				state: EBuildState.R,
			});
		}

		return buildings;
	}

	makeInitialUnits(): UnitStruct[]
	{
		return [
			{id: "au01", level: 20, cnt: 30000},
			{id: "au02", level: 20, cnt: 30000},
			{id: "au03", level: 20, cnt: 30000},
			{id: "au04", level: 20, cnt: 5000},
			{id: "au05", level: 20, cnt: 30000},
			{id: "au06", level: 20, cnt: 30000},
			{id: "au07", level: 20, cnt: 10000},
			{id: "au08", level: 20, cnt: 10000},
		];
	}

	//region space attack

	readonly firstTripConf: readonly IUnitCnt[] = [
		{id: "au01", /* robot */ cnt: 5000},
		{id: "au02", /* robot */ cnt: 4000},
		{id: "au03", /* robot */ cnt: 3000},
		/* no spy */
		{id: "au05", /* drone */ cnt: 1500},
		{id: "au06", /* drone */ cnt: 1000},
		{id: "au07", /* ram   */ cnt: 300},
		{id: "au08", /* art   */ cnt: 200},
	];

	readonly restTripConf: readonly IUnitCnt[] = [
		{id: "au01", /* robot */ cnt: 100},
		{id: "au08", /* art   */ cnt: 100},
	];

	getAttackDuration(attackNum: number): TSeconds
	{
		//@formatter:off
		const durationHours =
			attackNum <= 5 ? 24
			: attackNum <= 10 ? 12
			: attackNum <= 15 ? 6
			: attackNum <= 20 ? 3
			: attackNum <= 25 ? 2
			: 1;
		//@formatter:on

		return durationHours * 3600 as TSeconds;
	}

	getAttackParams(attackNum: number): {
		trips: {
			units: UnitStruct[],
		}[];
	}
	{
		const trips: { units: UnitStruct[] }[] = [];

		trips.push({
			units: this.makeTripUnits(this.firstTripConf, attackNum),
		});

		const tripsCnt = this.gameConf.aliens.trips_in_attack;

		for (let i = 1; i < tripsCnt; i++) {
			trips.push({
				units: this.makeTripUnits(this.restTripConf, attackNum),
			});
		}

		return {trips};
	}

	makeTripUnits(tripConf: readonly IUnitCnt[], attackNum: number): UnitStruct[]
	{
		const power = Math.min(attackNum, 50);
		const mult = Math.pow(1.09, power);
		return tripConf.map(it => ({
			id: it.id,
			level: 1,
			cnt: Math.round(it.cnt * mult),
		}));
	}


	//endregion

}