export const menuConf = [
	{ id: "main", name: "Main" },
	{
		id: "_test",
		name: "Test",
		draft: true,
		items: [
			{ id: "test_links", name: "Links" },
			{ id: "test_build_props", name: "Building Props" },
			{ id: "test_unit_props", name: "Unit Props" },
			{ id: "test_table", name: "Tables" },
			{ id: "test_img", name: "Images" },
			{ id: "test_invalid", name: "Invalid" },
			{ id: "test_ju", name: "Uli" },
		]
	},
	{
		id: "about_game",
		name: "About the Game",
		items: [
			{ id: "prehistory", name: "Pre-history" },
			{ id: "servers", name: "Servers",
			items: [
				{ id: "playground", name: "Playground" },
				{ id: "survival", name: "Free for All" },
				{ id: "ahnangs_invasion", name: "Ahnangs Invasion" },
			]
				},
			{ id: "map", name: "Map",
			items: [
				{ id: "types_plots", name: "Types of Plots" },
			]
				},
			{ id: "base", name: "Base" }
		]
	},
	{
		id: "factions",
		name: "Factions",
		items: [
			{ id: "fr1", name: "Masterminds" },
			{ id: "fr2", name: "Warriors" },
			{ id: "fr3", name: "Renegades" }
		]
	},
	{ id: "resources", name: "Resources" },
	{
		id: "buildings",
		name: "Buildings",
		items: [
			{ id: "mine_cp", name: "Caponium Mine" },
			{ id: "mine_nf", name: "Enefterium Mine" },
			{ id: "mine_wx", name: "Waxon Mine" },
			{ id: "mine_wc", name: "Wecanite Mine" },
			{ id: "stor_ctl", name: "Supply Depot" },
			{ id: "stor_sec", name: "Stash" },
			{ id: "factory_cp", name: "Caponium Factory" },
			{ id: "factory_nf", name: "Enefterium Factory" },
			{ id: "factory_wx", name: "Waxon Factory" },
			{ id: "factory_wc", name: "Wecanite Factory" },
			{ id: "base_dev", name: "Nexus" },
			{ id: "land_ctl", name: "Terraformator" },
			{ id: "market", name: "Market" },
			{ id: "trans_up", name: "Transport Hanger" },
			{ id: "datacenter", name: "Data Center" },
			{ id: "base_shield", name: "Fortification Сenter" },
			{ id: "land_unit", name: "Expansion Hub" },
			{ id: "army_ctl", name: "Central Command" },
			{ id: "units_gnd", name: "Robot Factory" },
			{ id: "units_air", name: "Air-Tech Factory" },
			{ id: "units_art", name: "Siege Factory" },
			{ id: "army_dev", name: "Academy" },
			{ id: "army_up", name: "Ammunition Center" },
			{ id: "speed_up", name: "Jet Center" },
			{ id: "army_shield", name: "Shield" }
		]
	}, {
		id: "units",
		name: "Units",
		items: [
			{
				id: "units_fr1",
				name: "Masterminds",
				items: [
					{ id: "ma01", name: "Scuttleshot" },
					{ id: "ma02", name: "Trooper" },
					{ id: "ma03", name: "Ornicopter" },
					{ id: "ma04", name: "Blaze Runner" },
					{ id: "ma05", name: "Rapid Strike" },
					{ id: "ma06", name: "BB-Bomber" },
					{ id: "ma07", name: "Ivan-Taran" },
					{ id: "ma08", name: "Bonz-Bomber" },
					{ id: "ma09", name: "Unixi" },
					{ id: "ma10", name: "Whirlwind" }
				]
			}, {
				id: "units_fr2",
				name: "Warriors",
				items: [
					{ id: "wa01", name: "MANT1S" },
					{ id: "wa02", name: "QuadBlaster" },
					{ id: "wa03", name: "Longshot" },
					{ id: "wa04", name: "Stinger" },
					{ id: "wa05", name: "Rapid Delivery" },
					{ id: "wa06", name: "AlphA" },
					{ id: "wa07", name: "Hellrainer" },
					{ id: "wa08", name: "T-Rekts" },
					{ id: "wa09", name: "Sneak Attaxx" },
					{ id: "wa10", name: "Colossus" }
				]
			}, {
				id: "units_fr3",
				name: "Renegades",
				items: [
					{ id: "re01", name: "Savage Blade" },
					{ id: "re02", name: "Guardian-RX" },
					{ id: "re03", name: "Wildcat" },
					{ id: "re04", name: "Spy-Fly" },
					{ id: "re05", name: "Leviathan" },
					{ id: "re06", name: "Rebel Striker" },
					{ id: "re07", name: "PowerShot" },
					{ id: "re08", name: "Tanunah" },
					{ id: "re09", name: "Malware" },
					{ id: "re10", name: "X-erector" }
				]
			}, {
				id: "units_uu",
				name: "Universal units",
				items: [
					{ id: "uu01", name: "Bobert" },
					{ id: "uu02", name: "Chad" },
					{ id: "uu03", name: "Quasimodo" }
				]
			}, {
				id: "units_au",
				name: "Ahnangs",
				items: [
					{ id: "au01", name: "Gorgon" },
					{ id: "au02", name: "Snarl" },
					{ id: "au03", name: "Grimgar" },
					{ id: "au04", name: "Razor" },
					{ id: "au05", name: "Furia" },
					{ id: "au06", name: "Interfeno" },
					{ id: "au07", name: "Krag" },
					{ id: "au08", name: "Slay" },

				]
			}
		]
	}, {
		id: "unit_actions",
		name: "Unit actions",
		items: [
			{ id: "battle", name: "Battle" },
			{ id: "support", name: "Support" },
			{ id: "rob", name: "Rob" },
			{ id: "spy", name: "Spy" },
		]
	}, {
		id: "gameplay",
		name: "Gameplay",
		items: [
			{ id: "assignments", name: "Assignments" },
			{ id: "ahnang_camp", name: "Ahnangs Camp" },
			{ id: "destroy_base", name: "Destroying the Enemies Base" },
			{ id: "capture_base", name: "Capturing the Enemies Base" },
			{ id: "wc_consump", name: "Wecanite Consumption" },
			{ id: "hunger", name: "Hunger" },
		]
	}, {
		id: "alliance",
		name: "Alliance",
	}, {
		id: "hero",
		name: "Hero",
	}, {
		id: "game_assets",
		name: "Game Assets",
		items: [
			{ id: "ass_mult_use", name: "Assets for Multiple Uses", items: [
				{ id: "land_nft", name: "Land" },
				{ id: "hero_nft", name: "Hero" 			
			}, {
				id: "hero_gear",
				name: "Hero Gear",
				items: [
					{ id: "head", name: "Gear for Head" },
					{ id: "left_hand", name: "Gear for Left Hand" },
					{ id: "right_hand", name: "Gear for Right Hand" },
					{ id: "body", name: "Gear for Body" },
					{ id: "legs", name: "Gear for Legs" },
					{ id: "transport", name: "Transport" },
					{ id: "devices", name: "Devices" },
				]
			}	
		]
	},
			{ id: "ass_one_use", name: "Assets for One-Time-Use", items:  [
				{ id: "univ_robots", name: "Universal Robots" },
				{ id: "cont_minerals", name: "Container with Resources" },
				{ id: "finish_now", name: "Finish Now" },
				{ id: "protect_dome", name: "Protective Dome" },
				{ id: "extra_slot", name: "Extra Slot" },
				{ id: "hero_goods", name: "Hero Goods" }
		]
	},
			]
		}
	]
