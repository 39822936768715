export class SecureUtil
{
	static readonly readableChars = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
	static readonly defaultChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	static makeRandomString(length: number, chars: string = this.defaultChars): string
	{
		let result = "";
		const charsLen = chars.length;
		for (let i = 0; i < length; i++) {
			result += chars.charAt(Math.floor(Math.random() * charsLen));
		}
		return result;
	}
}

