import { StrEnum } from "../enums/StrEnum";

//region GAME_TYPE

export const EGameType = StrEnum("EGameType", {
	playground: {},
	ffa: {},
	aliens: {},
});

export type TGameType = keyof typeof EGameType;

//endregion

//region GAME_SPEED

export const EGameSpeed = StrEnum("EGameSpeed", {
	normal: {},
	fast_1: {},
	fast_2: {},
});

export type TGameSpeed = keyof typeof EGameSpeed;

export interface IGameSpeedParams
{
	readonly speed_mult: float;
	readonly prod_time_mult: float;
	readonly build_time_mult: float;
	readonly mine_rate_mult: float;
}

export const GameSpeedConf: Record<TGameSpeed, IGameSpeedParams> = {
	normal: {
		speed_mult: 1,
		prod_time_mult: 1,
		build_time_mult: 1,
		mine_rate_mult: 1,
	},
	fast_1: {
		speed_mult: 3,
		prod_time_mult: 1.0 / 3.0,
		build_time_mult: 1.0 / 3.0,
		mine_rate_mult: 3,
	},
	fast_2: {
		speed_mult: 5,
		prod_time_mult: 0.2,
		build_time_mult: 0.2,
		mine_rate_mult: 5,
	},
};

export interface IGameParams
{
	readonly game_name: string;
	readonly game_speed: TGameSpeed;
	readonly game_type: TGameType;
	readonly config_file: string;
	readonly map_file: string;
}

//endregion

//region UI_THEME

export const EGameTheme = StrEnum("EGameTheme", {
	rplanet: {},
	hellowing: {},
	xmass: {},
});

export type TGameTheme = keyof typeof EGameTheme;

//endregion

//region GAME_STAGE

export const EGameState = StrEnum("EGameState", {
	init: {},
	open: {},
	closed: {},
	finished: {},
});

export type TGameState = keyof typeof EGameState;

export interface IGameFinalizaion
{
	readonly assets_detached: boolean;
	readonly prizes_given: boolean;
}


//endregion

//region FRACTIONS

export const EFraction = StrEnum("EFraction", {
	fr0: {},
	fr1: {},
	fr2: {},
	fr3: {},
	fr4: {},
});

export type TFraction = keyof typeof EFraction;

//endregion

//region RESOURCES

export const EResId = StrEnum("EResId", {
	cp: {},
	nf: {},
	wx: {},
	wc: {},
});

export type TResId = keyof typeof EResId;

export interface TResources
{
	readonly cp: number,
	readonly nf: number,
	readonly wx: number,
	readonly wc: number,
}

export interface StuffStruct
{
	readonly id: TResId;
	readonly amount: number;
}

export function makeResources(data: Partial<TResources> = {}): Mutable<TResources>
{
	return {
		cp: data.cp ?? 0,
		nf: data.nf ?? 0,
		wx: data.wx ?? 0,
		wc: data.wc ?? 0,
	};
}

export function makeAllResources(amount: number): TResources
{
	return {
		cp: amount,
		nf: amount,
		wx: amount,
		wc: amount,
	};
}

export function sumResources(data: TResources): number
{
	return data.cp +
	       data.nf +
	       data.wx +
	       data.wc;
}

export function resourcesEquals(a: TResources, b: TResources): boolean
{
	return a.cp === b.cp &&
	       a.nf === b.nf &&
	       a.wx === b.wx &&
	       a.wc === b.wc;
}

export function addResources(a: TResources, b: TResources): TResources
{
	return {
		cp: a.cp + b.cp,
		nf: a.nf + b.nf,
		wx: a.wx + b.wx,
		wc: a.wc + b.wc,
	};
}

export function multResources(res: TResources, value: number): TResources
{
	return {
		cp: res.cp * value,
		nf: res.nf * value,
		wx: res.wx * value,
		wc: res.wc * value,
	};
}

export function subtractResources(from: TResources, value: TResources): TResources
{
	return {
		cp: Math.max(from.cp - value.cp, 0),
		nf: Math.max(from.nf - value.nf, 0),
		wx: Math.max(from.wx - value.wx, 0),
		wc: Math.max(from.wc - value.wc, 0),
	};
}

export function subtractAllResources(from: TResources, value: number): TResources
{
	return {
		cp: Math.max(from.cp - value, 0),
		nf: Math.max(from.nf - value, 0),
		wx: Math.max(from.wx - value, 0),
		wc: Math.max(from.wc - value, 0),
	};
}

//endregion

//region STAT

export interface IStatStruct
{
	readonly droids: number;
	readonly attack: number;
	readonly defense: number;
	readonly resources: number;
}

//endregion

