export function Injectable(_?: any): ClassDecorator
{
	return () => {};
}

export function ApiProperty(_?: any): PropertyDecorator
{
	return () => {};
}

export function ApiPropertyOptional(_?: any): PropertyDecorator
{
	return () => {};
}
