import { menuConf } from "../resources/menu";

interface INodeConf
{
	id: string,
	name: string,
	items?: INodeConf[],
	draft?: boolean,
}

export type TNodeHandler = (node: WikiNode) => void

export class WikiModel
{
	readonly root: readonly WikiNode[];

	private readonly _nodesByPath = new Map<string, WikiNode>();
	private readonly _nodesById = new Map<string, WikiNode>();

	private _currentNode: WikiNode;

	constructor()
	{
		this.root = menuConf.map(it => new WikiNode(null, it, node => {
			this._nodesByPath.set(node.path, node);
			this._nodesById.set(node.id, node);
		}));

		if (this.root.length === 0)
			throw new Error("No nodes have been created");

		this._currentNode = this.root[0];
		this.refresh();
	}

	private refresh()
	{
		const node = this._currentNode;
		node.isSelected = true;
		for (let it: WikiNode | null = node; it; it = it.parent) {
			it.isOpen = true;
		}
	}

	toggleNode()
	{
		this._currentNode.isOpen = !this._currentNode.isOpen;
	}

	findById(id: string): WikiNode | undefined
	{
		return this._nodesById.get(id);
	}

	findByPath(path: string): WikiNode | undefined
	{
		return this._nodesByPath.get(path);
	}

	search(text: string): Set<WikiNode>
	{
		const result = new Set<WikiNode>();

		for (let node of this._nodesById.values()) {
			const words = node.name.toLowerCase().split(" ");
			const found = words.find(it => it.trim().startsWith(text));
			if (found) {
				result.add(node);
				let parent = node.parent;
				while (parent) {
					result.add(parent);
					parent = parent.parent;
				}
			}
		}

		return result;
	}

	get currentNode(): WikiNode
	{
		return this._currentNode;
	}

	set currentNode(node: WikiNode)
	{
		if (this._currentNode === node)
			return;

		if (this._currentNode)
			this._currentNode.isSelected = false;

		this._currentNode = node;
		this.refresh();
	}
}

export class WikiNode
{
	readonly id: string;
	readonly name: string;
	readonly path: string;
	readonly items: WikiNode[];
	readonly level: number;
	readonly isDraft: boolean;
	readonly parent: WikiNode | null;

	isOpen = false;
	isSelected = false;

	constructor(parent: WikiNode | null, data: INodeConf, register: (node: WikiNode) => void)
	{
		this.id = data.id;
		this.name = data.name;
		this.level = parent ? parent.level + 1 : 0;
		this.parent = parent;
		this.path = parent?.path
			? `${parent.path}/${data.id}`
			: `/${data.id}`;
		this.items = [];

		this.items = data.items?.map(
			it => new WikiNode(this, it, register)) ?? [];

		this.isDraft = Boolean(data.draft);

		register(this);
	}

	get isFolder(): boolean
	{
		return this.items.length > 0;
	}
}
