import { TFraction } from "@src/common/interfaces";
import { FractionTO } from "./conf_dto";

export class FractionType
{
	readonly id: TFraction;
	readonly transCap: number;
	readonly transSpeed: number;
	readonly builderCnt: number;

	constructor(data: FractionTO)
	{
		this.id = data.id;
		this.transCap = data.trans_cap;
		this.transSpeed = data.trans_speed;
		this.builderCnt = data.builder_cnt;
	}
}