import { StrEnum } from "../common";
import { EGameSpeed, TGameSpeed } from "../common/interfaces";
import { GameConf, IGameConf } from "../game/core/conf";
import { WikiNode, WikiModel } from "./WikiModel";
import game_conf_default from "../resources/game_configs/game_conf_default.json";
import preval from "babel-plugin-preval/macro";

export class AppContext
{
	onConfChanged?: () => void;

	readonly devMode: boolean;
	readonly model: WikiModel;

	readonly fileRoot: string;
	readonly WIKI_IDE: string;

	private _gameSpeed: TGameSpeed;
	private _conf: GameConf;

	constructor()
	{
		this.devMode = this.getDevMode();
		this.model = new WikiModel();

		this._gameSpeed = this.getSpeedParam();
		this._conf = this.makeConf();

		this.fileRoot = preval`module.exports = process.cwd()`;
		this.WIKI_IDE = preval`module.exports = process.env.WIKI_IDE` ?? "vscode";

		if (this.devMode) {
			console.log("[AppContext] fileRoot:", this.fileRoot);
			console.log("[AppContext] WIKI_IDE:", this.WIKI_IDE);
		}
	}

	get conf()
	{
		return this._conf;
	}

	get gameSpeed()
	{
		return this._gameSpeed;
	}

	setGameSpeed(value: TGameSpeed)
	{
		if (this._gameSpeed === value)
			return;

		this._gameSpeed = value;
		this._conf = this.makeConf();

		console.log("conf changed");
		if (this.onConfChanged)
			this.onConfChanged();

		this.setSpeedParam(value);
	}

	getSpeedParam(): TGameSpeed
	{
		const params = new URLSearchParams(window.location.search);
		const value = params.get("speed");
		return StrEnum.getOrDefault(EGameSpeed, value, "normal");
	}

	setSpeedParam(value: TGameSpeed)
	{
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		params.set("speed", value);
		url.search = params.toString();
		window.history.replaceState({}, '', url.toString());
	}

	private makeConf()
	{
		const clonedData = JSON.parse(JSON.stringify(game_conf_default)) as IGameConf;
		return new GameConf("gameId", {
			baseConfData: clonedData,
			assignData: [],
			mapData: [{
				id: "0:0",
				type: "b1",
				x: 0,
				y: 0,
				img: "",
			}],
			gameParams: {
				game_name: "Wiki",
				game_speed: this._gameSpeed,
				game_type: "aliens",
				config_file: "",
				map_file: "",
			},
		})
	}

	private getDevMode(): boolean
	{
		if (process.env.NODE_ENV === "development")
			return true;

		const params = new URLSearchParams(window.location.search);
		return params.has("draft");
	}

	resolveNodeByRoute(): WikiNode
	{
		let route = window.location.pathname;
		if (!route)
			return this.model.currentNode;

		if (!route.startsWith("/"))
			route = "/" + route;

		const node = this.model.findByPath(route);
		return node ?? this.model.currentNode;
	}

	getPageUrl()
	{
		return `${window.location.origin}${this.model.currentNode.path}`;
	}

	getFileUrl()
	{
		const projName = "rplanet-wiki";
		const filePath = `public/wiki${this.model.currentNode.path}.md`;

		switch (this.WIKI_IDE) {
			case "vscode":
				return `vscode://file/${this.fileRoot}/${filePath}`;
			default:
				return `jetbrains://${this.WIKI_IDE}/navigate/reference?project=${projName}&path=${filePath}`;
		}
	}

	getPageTitle()
	{
		return `R-Planet ${this.model.currentNode.name}`;
	}
}

export function initAppContext()
{
	app = new AppContext();
}

export let app: AppContext;