import { ApiProperty, ApiPropertyOptional } from "@nestjs/swagger";
import { TActionType, TBuildId, TFraction, TGameType, TLocType, TUnitType } from "@src/common/interfaces";

export class FractionTO
{
	@ApiProperty({type: String}) id!: TFraction;
	@ApiProperty({type: "int32"}) trans_cap!: number;
	@ApiProperty({type: "int32"}) trans_speed!: number;
	@ApiProperty({type: "int32"}) builder_cnt!: number;
}

export class ResourceTO
{
	@ApiProperty({type: "int64"}) cp!: number;
	@ApiProperty({type: "int64"}) nf!: number;
	@ApiProperty({type: "int64"}) wx!: number;
	@ApiProperty({type: "int64"}) wc!: number;
}

export class BuildReqTO
{
	@ApiProperty({type: String}) id!: TBuildId;
	@ApiProperty({type: "int32"}) level!: number;
}

export class LocTypeTO
{
	@ApiProperty({type: String}) id!: TLocType;
	@ApiProperty() mines!: ResourceTO;
	@ApiProperty({type: "int32"}) percent!: number;
}

export class BuildLevelTO
{
	@ApiProperty() resources!: ResourceTO;
	@ApiProperty({type: "int32"}) build_time!: number;
	@ApiProperty({type: "int32"}) droids!: number;
	@ApiProperty({type: "int32"}) value!: number;
}

export class BuildTypeTO
{
	@ApiProperty({type: "string", description: "Enum:EBuildId"})
	id!: TBuildId;

	@ApiProperty() group!: string;
	@ApiProperty({type: "int32"}) rank!: number;
	@ApiProperty({type: "int32"}) max_cnt!: number;
	@ApiProperty({type: "int32"}) max_level!: number;

	@ApiProperty({type: [BuildReqTO]})
	build_req!: BuildReqTO[];

	@ApiProperty({type: [BuildLevelTO]})
	levels!: BuildLevelTO[];
}

export class UnitTypeTO
{
	@ApiProperty() id!: string;
	@ApiProperty() fr_id!: string;
	@ApiProperty({type: String}) type!: TUnitType;
	@ApiProperty({type: "int32"}) speed!: number;
	@ApiProperty({type: "int32"}) attack!: number;
	@ApiProperty({type: "int32"}) gnd_defense!: number;
	@ApiProperty({type: "int32"}) air_defense!: number;
	@ApiProperty({type: "int32"}) wc_consum!: number;
	@ApiProperty({type: "int32"}) capacity!: number;

	@ApiProperty()
	dev_resources!: ResourceTO;
	@ApiProperty({type: [BuildReqTO]})
	dev_build_req!: BuildReqTO[];
	@ApiProperty({type: "int32"})
	dev_time!: number;

	@ApiProperty()
	up_resources!: ResourceTO;
	@ApiProperty({type: "int32"})
	up_time!: number;


	@ApiProperty() prod_build!: string;
	@ApiProperty() prod_resources!: ResourceTO;
	@ApiProperty({type: "int32"})
	prod_time!: number;
}

export class HeroPropConf
{
	@ApiProperty({type: "float"}) init!: number;
	@ApiProperty({type: "float"}) mult!: number;
	@ApiProperty({type: "int32"}) max_points!: number;
}

export class SettingsTO
{
	@ApiProperty() game_id!: string;
	@ApiProperty() game_name!: string;

	@ApiProperty({type: String})
	game_type!: TGameType;

	@ApiProperty({type: "int32"})
	game_end_players!: number;

	@ApiPropertyOptional({type: ["float"]})
	map_scales!: number[];

	@ApiProperty({type: "float"})
	map_icon_scale_mult!: number;

	@ApiProperty({type: "int32"}) base_mining_rate!: number;
	@ApiProperty({type: "int32"}) base_storage_cap!: number;
	@ApiProperty({type: "int32"}) base_protection!: number;
	@ApiProperty({type: "int32"}) build_slots!: number;
	@ApiProperty({type: "int32"}) build_slots_max!: number;
	@ApiProperty({type: "float"}) build_upgrade_refund_mult!: number;

	@ApiProperty({type: "float"}) unit_up_cost_mult!: number;
	@ApiProperty({type: "float"}) unit_up_fight_mult!: number;
	@ApiProperty({type: "float"}) unit_up_time_mult!: number;
	@ApiProperty({type: "int32"}) unit_max_level!: number;

	@ApiProperty({type: "int32"}) expansion_limit!: number;
	@ApiProperty({type: "int32"}) poll_interval_s!: number;
	@ApiProperty({type: "int32"}) flush_interval_s!: number;

	@ApiProperty({type: "int32"}) event_max_age_d!: number;

	@ApiProperty({type: "float"}) market_rate_min!: number;
	@ApiProperty({type: "float"}) market_rate_max!: number;
	@ApiProperty({type: "float"}) market_rate_step!: number;

	@ApiProperty({type: "int32"}) robots_arriving_time_s!: number;
	@ApiProperty({type: "int32"}) dome_arriving_time_s!: number;

	@ApiProperty({type: "int32"}) spy_attack!: number;
	@ApiProperty({type: "int32"}) spy_defense!: number;
	@ApiProperty({type: "int32"}) hacker_attack!: number;

	@ApiProperty({type: "int32"}) safe_period_days!: number;
	@ApiProperty({type: "int32"}) unit_recycling_wc_mult!: number;
	@ApiProperty({type: "int32"}) player_max_bases!: number;

	@ApiProperty({type: [BuildReqTO]})
	initial_buildings!: readonly BuildReqTO[];

	@ApiProperty()
	initial_resources!: ResourceTO;

	@ApiProperty()
	default_resources!: ResourceTO;

	@ApiProperty({type: "int32"})
	alliance_max_members!: number;

	@ApiProperty({type: "int32"})
	alliance_max_descr!: number;

	@ApiProperty({type: [String]})
	alliance_colors!: readonly string[];

	@ApiProperty({type: "int32"})
	trip_cancellation_time_s!: number;

	@ApiProperty() hero_health!: HeroPropConf;
	@ApiProperty() hero_health_rec!: HeroPropConf;
	@ApiProperty() hero_speed!: HeroPropConf;
	@ApiProperty() hero_force!: HeroPropConf;
	@ApiProperty() hero_unit_attack!: HeroPropConf;
	@ApiProperty() hero_unit_defense!: HeroPropConf;
	@ApiProperty() hero_unit_speed!: HeroPropConf;
	@ApiProperty() hero_mine_rate!: HeroPropConf;
	@ApiProperty() hero_native_cap!: HeroPropConf;
	@ApiProperty() hero_ai_rate!: HeroPropConf;

	@ApiProperty() hero_restore_res!: ResourceTO;
	@ApiProperty({type: "int32"}) hero_restore_time_s!: number;
	@ApiProperty({type: "int32"}) hero_upgrade_points!: number;

	@ApiProperty({type: "int32"}) alien_safe_period_days!: number;
	@ApiProperty({type: "int32"}) alien_capture_period_days!: number;

	@ApiProperty({type: "int32"}) ffa_end_players!: number;

	@ApiProperty({type: "int32"}) quest_point_period_h!: number;

	@ApiProperty({type: "int32"}) long_press_ms!: number;

	@ApiProperty()
	legacy_unit_prod!: boolean; //TOCLEAN: remove
}

export class ActionUnitTypesTO implements Record<TActionType, TUnitType[]>
{
	@ApiProperty({type: [String], description: "Enum:TUnitType"})
	settle!: TUnitType[];

	@ApiProperty({type: [String], description: "Enum:TUnitType"})
	support!: TUnitType[];

	@ApiProperty({type: [String], description: "Enum:TUnitType"})
	battle!: TUnitType[];

	@ApiProperty({type: [String], description: "Enum:TUnitType"})
	capture!: TUnitType[];

	@ApiProperty({type: [String], description: "Enum:TUnitType"})
	spy!: TUnitType[];
}


export class GameConfTO
{
	@ApiProperty()
	settings!: SettingsTO;

	@ApiProperty({type: [FractionTO]})
	fractions!: readonly FractionTO[];

	@ApiProperty({type: [BuildTypeTO]})
	buildings!: readonly BuildTypeTO[];

	@ApiProperty({type: [LocTypeTO]})
	locations!: readonly LocTypeTO[];

	@ApiProperty({type: [UnitTypeTO]})
	units!: readonly UnitTypeTO[];

	@ApiProperty()
	actionUnitTypes!: ActionUnitTypesTO;
}

export interface IGameConfSettings
{
	readonly base_mining_rate: int;
	readonly base_storage_cap: int;
	readonly base_protection: int;
	readonly build_slots: int;
	readonly build_slots_max: int;
	readonly build_upgrade_refund_mult: float;

	readonly unit_up_cost_mult: float;
	readonly unit_up_fight_mult: float;
	readonly unit_up_time_mult: float;
	readonly unit_max_level: float;

	readonly expansion_limit: float;
	readonly poll_interval_s: float;
	readonly flush_interval_s: float;

	readonly event_max_age_d: int;

	readonly market_rate_min: float;
	readonly market_rate_max: float;
	readonly market_rate_step: float;

	readonly robots_arriving_time_s: int;
	readonly dome_arriving_time_s: int;

	readonly spy_attack: int;
	readonly spy_defense: int;
	readonly hacker_attack: int;

	readonly safe_period_days: int;
	readonly unit_recycling_wc_mult: int;
	readonly player_max_bases: number;

	readonly initial_buildings: readonly BuildReqTO[];
	readonly initial_resources: ResourceTO;
	readonly default_resources: ResourceTO;

	readonly alliance_max_members: int;
	readonly alliance_max_descr: int;
	readonly alliance_colors: readonly string[];

	readonly trip_cancellation_time_s: int;

	readonly hero_health: HeroPropConf;
	readonly hero_health_rec: HeroPropConf;
	readonly hero_speed: HeroPropConf;
	readonly hero_force: HeroPropConf;
	readonly hero_unit_attack: HeroPropConf;
	readonly hero_unit_defense: HeroPropConf;
	readonly hero_unit_speed: HeroPropConf;
	readonly hero_mine_rate: HeroPropConf;
	readonly hero_native_cap: HeroPropConf;
	readonly hero_ai_rate: HeroPropConf;

	readonly hero_restore_res: ResourceTO;
	readonly hero_restore_time_s: int;
	readonly hero_upgrade_points: int;

	readonly quest_point_period_h: int;

	readonly alien_safe_period_days: int;
	readonly alien_capture_period_days: number;

	readonly ffa_end_players: int;
}

export interface IGameConf
{
	readonly settings: IGameConfSettings;
	readonly fractions: readonly FractionTO[];
	readonly buildings: readonly BuildTypeTO[];
	readonly locations: readonly LocTypeTO[];
	readonly units: readonly UnitTypeTO[];
}