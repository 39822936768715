import { check } from "@src/common/utils";
import { IAssignment, IPlayerAssign, TAssignId, TRewardType } from "../interfaces";

export class GameAssingConf
{
	constructor(readonly data: readonly IAssignment[])
	{
	}

	getById(id: TAssignId): IAssignment
	{
		const found = this.data.find(it => it.id === id);
		check(found, `assignment not found: ${id}`);
		return found;
	}

	getByRewardType(type: TRewardType): IAssignment
	{
		const found = this.data.find(it => it.reward[type]);
		check(found, `assignment not found: ${type}`);
		return found;
	}

	getNextAssigns(active: readonly IPlayerAssign[],
	               completed: readonly TAssignId[]): IAssignment[]
	{
		const result: IAssignment[] = [];

		for (const entry of this.data) {
			if (completed.includes(entry.id))
				continue;

			if (active.find(it => it.id === entry.id))
				continue;

			const meetsRequirement = !entry.requirement
			                         || completed.includes(entry.requirement);

			if (meetsRequirement)
				result.push(entry);
		}

		return result;
	}

	makeByRewardType(type: TRewardType): IPlayerAssign
	{
		return this.toPlayerAssign(this.getByRewardType(type));
	}

	makeAssignment(id: TAssignId): IPlayerAssign
	{
		const assign = this.data.find(it => it.id === id);
		check(assign, `assignment not found: ${id}`);
		return this.toPlayerAssign(assign);
	}

	makeInitialAssigns(): IPlayerAssign[]
	{
		return this.getNextAssigns([], [])
			.map(it => this.toPlayerAssign(it));
	}

	makeNextAssigns(active: readonly IPlayerAssign[],
	                completed: readonly TAssignId[]): IPlayerAssign[]
	{
		return this.getNextAssigns(active, completed)
			.map(it => this.toPlayerAssign(it));
	}

	toPlayerAssign(assign: IAssignment): IPlayerAssign
	{
		return {
			id: assign.id,
			type: assign.type,
			param: assign.param,
			reward: assign.reward,
			done: false,
		};
	}
}