import { StrEnum } from "../enums/StrEnum";
import { TBuildId, TBuildState } from "./build_types";
import { TFraction } from "./game_types";

export const EBaseType = StrEnum("EBaseType", {
	b0: {},
	b1: {},
	b2: {},
	b3: {},
	b4: {},
	b5: {},
	b6: {},
});

export type TBaseType = keyof typeof EBaseType;

export const ELocType = StrEnum("ELocType", {
	e: {},

	e1: {},   //toclean: remove after playgrounds(1, 2) end
	e2: {},   //toclean: remove after playgrounds(1, 2) end
	e3: {},   //toclean: remove after playgrounds(1, 2) end
	e4: {},   //toclean: remove after playgrounds(1, 2) end
	e5: {},   //toclean: remove after playgrounds(1, 2) end
	e6: {},   //toclean: remove after playgrounds(1, 2) end
	e7: {},   //toclean: remove after playgrounds(1, 2) end
	e8: {},   //toclean: remove after playgrounds(1, 2) end
	e9: {},   //toclean: remove after playgrounds(1, 2) end
	e10: {},  //toclean: remove after playgrounds(1, 2) end
	e11: {},  //toclean: remove after playgrounds(1, 2) end
	e12: {},  //toclean: remove after playgrounds(1, 2) end
	e13: {},  //toclean: remove after playgrounds(1, 2) end

	/// should include all of TBaseType
	b0: {},
	b1: {},
	b2: {},
	b3: {},
	b4: {},
	b5: {},
	b6: {},
});

export type TLocType = keyof typeof ELocType;

export type TPosition = {
	readonly x: number,
	readonly y: number,
};

export interface ILocInit
{
	id: string;
	x: number;
	y: number;
	type: TLocType;
	img?: string;   // absent in game0...game3
}

export interface LocOwnerStruct
{
	readonly id: TUserId;
	readonly fr: TFraction;
	readonly name: string;
	readonly alliance?: string;
}

export interface IOwner
{
	readonly id: number;
	readonly name: string;
}

export interface BuildStruct
{
	readonly id: TBuildId;
	readonly slot: number;
	readonly level: number;
	readonly state: TBuildState;
}

export type MutableBuildStruct = Compute<
	PartialMutable<BuildStruct, "level" | "state">
>;
