export class ObjectUtil
{
	static selectPartial<T>(source: T, keys: ReadonlyArray<keyof T>): Partial<T>
	{
		const result: Partial<T> = {};
		for (const key of keys) {
			result[key] = source[key];
		}
		return result;
	}

	static removeUndefined<T extends {}>(source: T): T
	{
		const result: TObject = {};
		for (const key of Object.keys(source)) {
			const value = (source as TObject)[key];
			if (value !== undefined)
				result[key] = value;
		}
		return result as T;
	}

	static getPartialIf<T>(condition: boolean, ifValue: Partial<T>, elseValue: Partial<T> = {})
	{
		return condition ? ifValue : elseValue;
	}

	static toError(err: unknown): Error
	{
		return err instanceof Error
			? err
			: new Error(String(err));
	}

	static jsonClone<T>(value: T): T
	{
		return JSON.parse(JSON.stringify(value));
	}
}