import React, {useState} from "react";
import { useForceUpdate } from "../../react_util";
import { SideBarContext } from "../App";
import { app } from "../AppContext";
import { TNodeHandler, WikiNode } from "../WikiModel";

const emptyNodeSet = new Set<WikiNode>();
interface IFilter
{
	text: string,
	nodes: Set<WikiNode>
}

export function NavigationPanel(p: {onNodeSelected: TNodeHandler, onShow: boolean})
{
	const forceUpdate = useForceUpdate();

	const [filter, setFilter] = useState<IFilter>({
		text: "",
		nodes: emptyNodeSet
	});

	const onNodeClick = (node: WikiNode) => {
		if (node === app.model.currentNode) {
			app.model.toggleNode();
		} else {
			app.model.currentNode = node;
			p.onNodeSelected(node);
		}

		forceUpdate();
	};

	const onTextChange = (event) => {
		const text = String(event.target.value).trim().toLowerCase();
		const nodes = text
			? app.model.search(text)
			: emptyNodeSet;
		setFilter({text, nodes});
	};

	const onTextClear = () => {
		setFilter({text: "", nodes: emptyNodeSet});
	};

	return (
		<div className={`NavigationTree ${p.onShow ? "side-bar-show" : ""}`}>
			<h2 className={'navigationTitle'}>
				R-Planet Wiki
			</h2>

			<div className="input-box">
				<input type="text"
				       value={filter.text}
				       placeholder={"Search"}
				       onChange={onTextChange}
				/>
				<button className="input-button" onClick={onTextClear}>✕</button>
			</div>

			<hr style={{
				marginTop: "12px",
				marginBottom: "8px"
			}}/>

			<NodeList nodes={app.model.root} filter={filter} onClick={onNodeClick}/>
		</div>
	);
}

function NodeList(p: {
	nodes: readonly WikiNode[],
	filter: IFilter,
	onClick: TNodeHandler
})
{
	return (
		<>{
			p.nodes
				.filter(it => !it.isDraft || app.devMode)
				.filter(it => !p.filter.text || p.filter.nodes.has(it))
				.map(it => it.isFolder
					? <FolderNode key={it.path} node={it} filter={p.filter} onClick={p.onClick}/>
					: <SimpleNode key={it.path} node={it} filter={p.filter} onClick={p.onClick}/>)
		}</>
	);
}

function FolderNode(p: {node: WikiNode, filter: IFilter, onClick: TNodeHandler})
{
	const text = makeNodeText(p.node.name, p.filter);

	return (
		<div>
			<div className={`nav-node-folder ${p.node.isSelected && "nav-node-selected"} `}>
				<a className={`${p.node.isDraft ? "nav-node-draft" : null}`}
				   href={p.node.path}
				   onClick={(e) => {
					   p.onClick(p.node);
					   e.preventDefault();
				   }}
				>
					{text}
					{p.node.isDraft && " (draft)"}
					{p.node.isOpen ? " -" : " +"}
				</a>
			</div>
			<div style={{
				marginLeft: `${(p.node.level + 1) * 16}px`,
				display: p.node.isOpen || p.filter.text ? "block" : "none"
			}}>
				<NodeList nodes={p.node.items} filter={p.filter} onClick={p.onClick}/>
			</div>
		</div>
	);
}

function SimpleNode(p: {node: WikiNode, filter: IFilter, onClick: TNodeHandler}) {
    const text = makeNodeText(p.node.name, p.filter);

    function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        p.onClick(p.node);
        e.preventDefault();
    }

    return (
        <SideBarContext.Consumer>
            {({togglerSideBar}) => (
                <div
                    className={`nav-node-leaf ${p.node.isSelected && "nav-node-selected"}`}>
                    <a className={`${p.node.isDraft ? "nav-node-draft" : null}`}
                       href={p.node.path}
                       onClick={(e) => {
                           togglerSideBar();
                           onClick(e)
                       }}
                    >
                        {text}
                        {p.node.isDraft && " (draft)"}
                    </a>
                </div>
            )}
        </SideBarContext.Consumer>
    );
}


function makeNodeText(text: string, filter: IFilter)
{
	if (!filter.text)
		return <span>{text}</span>;

	const index = text.toLowerCase().indexOf(filter.text);
	if (index < 0 || (index > 1 && text[index - 1] !== " "))
		return <span>{text}</span>;

	return <span>{text.substring(0, index)}
		<span className="input-highlight">{text.substring(index, index + filter.text.length)}</span>
		<span>{text.substring(index + filter.text.length)}</span>
	</span>;
}
