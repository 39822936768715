type TEnumObject = Record<string, unknown>;

type TStringEnum<T extends Record<string, {}>> = {
	[P in keyof T]: P;
}

function makeEnum<T extends {}>(name: string, keys: T): TStringEnum<T>
{
	const result: TEnumObject = {};

	Object.defineProperty(result, "toString", {
		value: () => name,
		writable: false,
		enumerable: false,
		configurable: false,
	});

	for (const key in keys) {
		result[key] = key;
	}
	return Object.freeze(result) as TStringEnum<T>;
}

interface IStringEnumStatic
{
	has: <T extends TEnumObject>(enumObject: T, value: unknown) => value is keyof T;
	check: <T extends TEnumObject>(enumObject: T, value: unknown) => keyof T;
	keys: <T extends TEnumObject>(enumObject: T) => Array<keyof T>;
	getOrDefault: <T extends TEnumObject>(enumObject: T, value: unknown, defaultValue: keyof T) => keyof T;
	getOrNull: <T extends TEnumObject>(enumObject: T, value: unknown) => keyof T | null;
}

function enumContainsValue<T extends TEnumObject>(enumObject: T, value: unknown): value is keyof T
{
	return typeof(value) === "string" && enumObject[value] === value;
}

Object.defineProperty(makeEnum, "getOrNull", {
	value: <T extends TEnumObject>(enumObject: T, value: unknown) =>
		enumContainsValue(enumObject, value) ? value : null,
	writable: false,
	enumerable: false,
	configurable: false,
});

Object.defineProperty(makeEnum, "getOrDefault", {
	value: <T extends TEnumObject>(enumObject: T, value: unknown, defaultValue: keyof T) =>
		enumContainsValue(enumObject, value) ? value : defaultValue,
	writable: false,
	enumerable: false,
	configurable: false,
});

Object.defineProperty(makeEnum, "keys", {
	value: <T extends TEnumObject>(enumObject: T) => Object.keys(enumObject) as Array<keyof T>,
	writable: false,
	enumerable: false,
	configurable: false,
});

Object.defineProperty(makeEnum, "has", {
	value: enumContainsValue,
	writable: false,
	enumerable: false,
	configurable: false,
});

Object.defineProperty(makeEnum, "check", {
	value: <T extends TEnumObject>(enumObject: T, value: unknown): keyof T => {
		if (enumContainsValue(enumObject, value))
			return value as keyof T;

		throw new Error(`Invalid enum value ${enumObject}[${value}]}`);
	},
	writable: false,
	enumerable: false,
	configurable: false,
});

export const StrEnum = makeEnum as typeof makeEnum & IStringEnumStatic;

